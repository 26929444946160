import React, { useState } from "react";

import "./DistributorDetails.scss";
import logoUrl from "@images/acolin-logo-no-strapline.svg";
import { Button, Card, CardContent, ContactInfo, Chip, ResponsiveTable, Rtf } from "@components/common";
import classNames from "classnames";
import { format } from "date-fns";
import { Dp } from "../types";
import Navigation from "@components/common/Navigation";
import { Grid, GridColumn, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import Modal from "@components/common/Modal";

const baseClass = "acl-distributor-details";

interface IProps {
    dp: Dp;
    onClose(): void;
}

type RowProps = {
    label: string;
    value?: string | TrustedHTML;
    componentProp?: React.ReactNode;
};

const Row: React.FC<RowProps> = ({ label, value, componentProp }) =>
    (value || componentProp) && (
        <div className={`${baseClass}__dl-row`}>
            <dt>{label}</dt>
            <dd className={`${baseClass}__dl-row-value`}>{value ? <Rtf message={value} /> : componentProp}</dd>
        </div>
    );

interface IStrategiesProps {
    items: string[];
}

export const Strategies: React.FC<IStrategiesProps> = ({ items }) => {
    const strategies = items?.map(item => ({ text: item, value: item, disabled: true }));
    return strategies.length > 0 && strategies.map(strategy => <Chip name={strategy.value} type="disabled" className={`${baseClass}__chip`} />);
};

const columns = [
    { field: "name", title: "Sub-Distributor" },
    { field: "city", title: "City" },
    { field: "country", title: "Country" },
];

const initialSort: Array<SortDescriptor> = [{ field: "name", dir: "asc" }];

const DistributorDetails: React.FC<IProps> = ({ dp, onClose = (): void => undefined }) => {
    const [selectedTab, setSelectedTab] = useState<"general" | "subDps">("general");
    const [sort, setSort] = React.useState(initialSort);

    const shouldShowFundsDetails =
        dp?.investmentStrategies ||
        dp?.liquidityRequirements ||
        dp?.trackRecordRequirements ||
        dp?.fundDocumentSource ||
        dp?.fundStaticDataSource ||
        dp?.fundNAVDataSource;

    const shouldShowCompanyDescriptionNotes = dp?.description || dp?.acolinNotes;

    const routes = [
        { label: "General Information", isActive: selectedTab === "general" },
        { label: "Sub-Distributors Network", isActive: selectedTab === "subDps" },
    ];

    return (
        <Modal className={`${baseClass}__dialog`} onClose={onClose}>
            <>
                <div className={`${baseClass}__top-section`}>
                    <img className={`${baseClass}__logo`} src={logoUrl} alt="Acolin" />
                    <p>connecting you to opportunity</p>
                </div>
                {dp.subDistributionPartners?.length > 0 && (
                    <Navigation routes={routes} onClick={(label: string): void => setSelectedTab(label === "General Information" ? "general" : "subDps")} />
                )}
                {selectedTab === "general" ? (
                    <>
                        <div className={classNames(`${baseClass}__cards`, { [`${baseClass}__cards-noCrm`]: !dp?.responsibleCRM?.fullname })}>
                            <Card className={`${baseClass}__content`}>
                                <CardContent>
                                    <dl>
                                        <Row label="Name" value={dp?.name} />
                                        <Row label="Acolin ID" value={dp?.omniId} />
                                        <Row label="Category" value={dp?.category} />
                                        <Row label="Address" value={dp?.address} />
                                        <Row label="City" value={dp?.city} />
                                        <Row label="Country" value={dp?.country} />
                                        <Row label="Risk Score" value={dp?.risk && `${dp.risk}%`} />
                                        <Row label="Last Due Diligence" value={dp?.lastDDDate && format(new Date(dp.lastDDDate), "yyyy-MM-dd")} />
                                        <Row
                                            label="Website"
                                            componentProp={
                                                dp?.website && (
                                                    <a href={dp.website} target="_blank">
                                                        {dp.website}
                                                    </a>
                                                )
                                            }
                                        />
                                    </dl>
                                </CardContent>
                            </Card>

                            {dp?.responsibleCRM?.fullname && (
                                <ContactInfo
                                    className={`${baseClass}__contact`}
                                    title="Your Acolin Specialist"
                                    fullName={dp.responsibleCRM.fullname}
                                    email={dp.responsibleCRM.internalemailaddress}
                                    phoneNumber={dp.responsibleCRM.address1_telephone1}
                                />
                            )}
                        </div>
                        {shouldShowCompanyDescriptionNotes && (
                            <Card className={classNames(`${baseClass}__content`, `${baseClass}__content-description`)}>
                                <CardContent>
                                    <dl>
                                        <Row label="Company description" value={dp?.description} />
                                        <br />
                                        <Row label="Notes" value={dp?.acolinNotes} />
                                    </dl>
                                </CardContent>
                            </Card>
                        )}
                        {shouldShowFundsDetails && (
                            <Card className={classNames(`${baseClass}__content`, `${baseClass}__content-fundsDetails`)}>
                                <CardContent>
                                    <dl>
                                        <Row
                                            label="Investment strategies"
                                            componentProp={dp?.investmentStrategies && <Strategies items={dp.investmentStrategies} />}
                                        />
                                        <Row label="Liquidity requirements" value={dp?.liquidityRequirements} />
                                        <Row label="Track record requirements" value={dp?.trackRecordRequirements} />
                                        <Row label="Fund Document Source" value={dp?.fundDocumentSource} />
                                        <Row label="Fund Static Data Source" value={dp?.fundStaticDataSource} />
                                        <Row label="Fund NAV Data Source" value={dp?.fundNAVDataSource} />
                                    </dl>
                                </CardContent>
                            </Card>
                        )}
                    </>
                ) : (
                    <Card>
                        <CardContent>
                            <ResponsiveTable>
                                <Grid
                                    className={`${baseClass}__subDps`}
                                    data={orderBy(dp.subDistributionPartners, sort)}
                                    sortable={true}
                                    sort={sort}
                                    onSortChange={(e: GridSortChangeEvent): void => {
                                        setSort(e.sort);
                                    }}
                                    filterable={false}
                                    scrollable="none"
                                >
                                    {columns.map(({ field, ...other }) => (
                                        <GridColumn key={field} field={field} {...other} />
                                    ))}
                                </Grid>
                            </ResponsiveTable>
                        </CardContent>
                    </Card>
                )}

                <div className={`${baseClass}__actions`}>
                    <Button themeColor="primary" onClick={onClose}>
                        Close
                    </Button>
                </div>
            </>
        </Modal>
    );
};

export default DistributorDetails;
