import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import styles from "./DateRangeFilterCell.module.scss";

interface IProps {
    min: Date;
    max: Date;
    wrapperClassName?: string;
    isCompact?: boolean;
    onDateFilterChange({ value, operator }: { value: Date; operator: string }): void;
}

const DateRangeFilterCell: React.FC<IProps> = props => {
    const [startDate, setStartDate] = useState(props.min);
    const [endDate, setEndDate] = useState(props.max);

    useEffect(() => {
        setStartDate(props.min);
    }, [props.min]);
    useEffect(() => {
        setEndDate(props.max);
    }, [props.max]);

    return (
        <>
            <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                    setStartDate(date);
                    setTimeout(() => {
                        props.onDateFilterChange({
                            value: date,
                            operator: "gte",
                        });
                    }, 100);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="From"
                className={styles.rangeDatePicker}
                showPopperArrow={false}
                dateFormat="yyyy-MM-dd"
                wrapperClassName={props.wrapperClassName}
            />
            <DatePicker
                selected={endDate}
                onChange={(date: Date) => {
                    setEndDate(date);
                    setTimeout(() => {
                        props.onDateFilterChange({
                            value: date,
                            operator: "lte",
                        });
                    }, 100);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="To"
                className={styles.rangeDatePicker}
                showPopperArrow={false}
                dateFormat="yyyy-MM-dd"
                wrapperClassName={props.wrapperClassName}
            />
        </>
    );
};

export default React.memo(DateRangeFilterCell);
