import React, { ReactNode } from "react";

import styles from "./ErrorMessage.module.scss";
import classNames from "classnames";

export interface ButtonProps {
    children?: ReactNode;
    className?: string;
}

const ErrorMessage: React.FC<ButtonProps> = ({ children, className, ...props }) => (
    <div className={classNames(styles.errorMessage, className)} {...props}>
        {children}
    </div>
);

export default ErrorMessage;
