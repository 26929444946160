import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as uuid from "uuid";
import "../InitiateOnboarding.scss";
import { Button, Card, CardContent, CardHeader, Chip, Icon, ConfirmationDialog, ErrorMessage } from "@components/common";
import { Fund, FundType, initialSingleFund, initialSubFund, initialUmbrellaFund } from "../../types";
import FundDetails from "./StepFundsDetails";
import LabeledSelect from "@components/common/LabeledSelect";
import classNames from "classnames";
import Modal from "@components/common/Modal";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "@components/common/LabeledInput";

const baseClass = "acl-page-initiate-onboarding";

type Parent = {
    id: string;
    name: string;
};

type CreateData = {
    fundType: FundType;
    parent?: Parent;
    name?: string;
};

type CreateDialogProps = {
    fundType: FundType;
    parent?: Parent;
    parentList?: Parent[];
    onClose(): void;
    onCreate?(data: CreateData): void;
    oldUmbrellaFunds: any[];
};

const initialValues: CreateData = {
    fundType: null,
    name: "",
    parent: null,
};

const CreateDialog: React.FC<CreateDialogProps> = ({ fundType, parent, parentList, onClose = (): void => undefined, onCreate, oldUmbrellaFunds }) => {
    const nameLabel = `New ${fundType} Name`;
    const oldUmbrellaParents: Parent[] = useMemo(
        () => oldUmbrellaFunds.map(fund => ({ id: fund?.id?.toString(), name: fund?.name })).filter(parent => parent.id && parent.name),
        [oldUmbrellaFunds],
    );
    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm({
        defaultValues: { ...initialValues, fundType: fundType, parent },
        mode: "onChange",
        shouldUnregister: true,
    });

    const parentOptons = useMemo(() => {
        const parentNames = new Set<string>();
        const allParents = [...parentList, ...oldUmbrellaParents];
        return allParents.filter(parent => {
            if (parentNames.has(parent.name)) {
                return false;
            }
            parentNames.add(parent.name);
            return true;
        });
    }, [parentList, oldUmbrellaParents]);

    const DropDownComponent = useCallback(
        (props: any): JSX.Element => (
            <LabeledSelect
                label="Choose Umbrella"
                options={parentOptons}
                defaultValue={null}
                placeholder="Select"
                value={props.value}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                required
                noOptionsMessage={() => (
                    <h4>
                        <br />
                        No data found.
                        <br />
                        Create Umbrella first.
                    </h4>
                )}
                onChange={(event): void => props.onChange(event)}
            />
        ),
        [parentList, parent, oldUmbrellaFunds],
    );

    const parentValidator = (value: Parent) => (value ? true : "Parent must be selected");

    return (
        <Modal className={`${baseClass}`} title="Add New" onClose={onClose} width="350px">
            <form onSubmit={handleSubmit(data => onCreate({ ...data, fundType: fundType }))} className={`${baseClass}__form`}>
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: { value: true, message: "This field is required" } }}
                    render={({ field }) => {
                        const { ref, ...rest } = field;
                        return <LabeledInput error={errors.name?.message} label={nameLabel} {...rest} />;
                    }}
                />
                {fundType === FundType.SUBFUND && (
                    <>
                        <Controller
                            name="parent"
                            control={control}
                            rules={{ required: { value: true, message: "This field is required" }, validate: parentValidator }}
                            render={({ field }) => {
                                const { ref, ...rest } = field;
                                return <DropDownComponent error={errors.name?.message} {...rest} />;
                            }}
                        />
                    </>
                )}
                <div className={`${baseClass}__actions`}>
                    <Button themeColor="primary" type="submit" disabled={!isDirty || !isValid}>
                        Create
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

type StepFundsProps = {
    funds: Fund[];
    setFunds: (data: Fund[]) => void;
    oldUmbrellaFunds: any[];
};

type FundsViewProps = {
    funds: Fund[];
    selectedFund?: Fund;
    onCreate: (data: CreateData, openModal?: boolean) => void;
    onScrollTo?: () => void;
    onFundClick?: (fund: Fund) => void;
    onFundDelete: (fundId: string, parentId?: string) => void;
};

type FundProps = {
    fund: Fund;
    parent?: Fund;
    selectedFund?: Fund;
    onCreate?: (data: CreateData, openModal?: boolean) => void;
    onFundClick?: (fund: Fund) => void;
    onDelete: (fundId: string, parentId?: string) => void;
};

const FundComponent: React.FC<FundProps> = ({ fund, parent, selectedFund, onCreate, onFundClick, onDelete }) => {
    const [shouldDeleteModal, setShouldDeleteModal] = useState<boolean>(false);

    const isUmbrella = fund.fundType === FundType.UMBRELLA;
    const isSubFund = fund.fundType === FundType.SUBFUND;
    const isShareClass = fund.fundType === FundType.SHARECLASS;

    const { fundType, name, id } = fund;

    const isSelected = id === selectedFund?.id;

    const classes = ["fund"];
    if (isSubFund || isShareClass) {
        classes.push("childFund");
    }
    classes.push("newFund");
    if (isSelected) {
        classes.push("selectedFund");
    }

    const handleCreate = useCallback(() => {
        if (!onCreate) {
            return;
        }

        const { fundType } = fund;
        if (fundType === FundType.SHARECLASS) {
            return;
        } else if (fundType === FundType.UMBRELLA) {
            onCreate({ fundType: FundType.SUBFUND, parent: fund }, true);
        } else {
            onCreate({ fundType: FundType.SHARECLASS, parent: fund }, true);
        }
    }, [fund, parent, onCreate]);

    const createChildButtonLabel: string = useMemo(() => {
        switch (fund.fundType) {
            case FundType.SINGLE_FUND:
            case FundType.SUBFUND:
                return "ADD ISIN";
            case FundType.UMBRELLA:
                return "ADD SUBFUND";
            default:
                return "";
        }
    }, [fund]);

    const mappedClasses = classes.map(item => `${baseClass}__${item}`);
    const hasChildren = fund.children?.length > 0 || false;
    const canCreateChild = isUmbrella;

    const handleFundClick = (): void => {
        if (!onFundClick) {
            return;
        }
        onFundClick(fund);
    };

    function handleDeleteClick(): void {
        setShouldDeleteModal(true);
    }

    return (
        <div className={hasChildren && `${baseClass}__fundContainer`}>
            <div className={classNames(`${baseClass}__fundLayout`)}>
                <div className={classNames([...mappedClasses])} onClick={handleFundClick}>
                    <Chip name={fundType} type="disabled" size="small" className={classNames(`${baseClass}__chip`)} />
                    {name}
                </div>
                <div className={classNames(`${baseClass}__actionContainer`)}>
                    {canCreateChild && (
                        <Button themeColor="primary" onClick={handleCreate}>
                            {createChildButtonLabel}
                        </Button>
                    )}
                </div>
                <Icon title="" name="remove-circle" spacing="both" className={`${baseClass}__trash`} onClick={() => handleDeleteClick()} />
                <ConfirmationDialog
                    shouldOpen={shouldDeleteModal}
                    onClose={(): void => {
                        setShouldDeleteModal(false);
                    }}
                    onConfirm={(): void => {
                        onDelete(fund.id, parent?.id);
                    }}
                >
                    <div className={`${baseClass}__success-message`}>Are you sure that you want to discard this onboarding entry?</div>
                </ConfirmationDialog>
            </div>

            {hasChildren && (
                <div className={classNames(`${baseClass}__childFundContainer`)}>
                    {fund.children?.map(subfund => (
                        <FundComponent
                            key={subfund.id}
                            fund={subfund}
                            parent={fund}
                            onFundClick={onFundClick}
                            selectedFund={selectedFund}
                            onDelete={onDelete}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const NewFundsView: React.FC<FundsViewProps> = ({ funds, selectedFund, onCreate, onScrollTo, onFundClick, onFundDelete }) => (
    <Card className={`${baseClass}__overview`}>
        <CardHeader>
            <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                <div style={{ alignContent: "center", alignItems: "center", margin: "0px auto" }}>
                    <h2 className={`${baseClass}__title`}>Add New</h2>
                </div>
                <div className={classNames(`${baseClass}__createActionContainer`)}>
                    <Button themeColor="primary" onClick={(): void => onCreate({ fundType: FundType.SINGLE_FUND }, true)}>
                        Single Fund
                    </Button>
                    <Button themeColor="primary" onClick={(): void => onCreate({ fundType: FundType.UMBRELLA }, true)}>
                        Umbrella Fund
                    </Button>
                    <Button themeColor="primary" onClick={(): void => onCreate({ fundType: FundType.SUBFUND }, true)}>
                        Subfund
                    </Button>
                </div>
            </div>
        </CardHeader>
        <CardContent>
            {funds.map(fund => (
                <div className={`${baseClass}__rowFund`}>
                    <div className={`${baseClass}__fundSize`}>
                        <FundComponent
                            key={fund.id}
                            fund={fund}
                            onCreate={onCreate}
                            selectedFund={selectedFund}
                            onDelete={onFundDelete}
                            onFundClick={onFundClick}
                        />
                    </div>
                </div>
            ))}
        </CardContent>
    </Card>
);

type CreateNew = {
    show: boolean;
    fundType: FundType;
    parent: Parent;
};

const initialCreateNew: CreateNew = {
    show: false,
    fundType: null,
    parent: null,
};

const getAllFundIds = (funds: Fund[]): string[] =>
    funds.flatMap(fund => {
        if (fund.children?.length > 0) {
            return [fund.id, ...getAllFundIds(fund.children)];
        } else {
            return [fund.id];
        }
    });

const StepFunds: React.FC<StepFundsProps> = ({ funds, setFunds, oldUmbrellaFunds }) => {
    const [createNew, setCreateNew] = useState<CreateNew>(initialCreateNew);
    const [selectedFund, setSelectedFund] = useState<Fund>(null);

    const myAcolinDataRef = useRef(null);

    const deleteFund = (fundId: string, parentId?: string): void => {
        const isSelectedFundDeleted = selectedFund?.id === fundId;
        const isSelectedFundsParentDeleted = funds.find(fund => fund?.children?.find(child => child?.id === selectedFund?.id));

        if (isSelectedFundDeleted || isSelectedFundsParentDeleted) {
            setSelectedFund(null);
        }

        if (parentId !== undefined) {
            const updatedFunds = funds.map(fund => {
                if (fund.id === parentId) {
                    const updatedChildren = fund?.children?.filter(child => child.id !== fundId);
                    return { ...fund, children: updatedChildren };
                }
                return fund;
            });
            setFunds(updatedFunds);
        } else {
            const updatedFunds = funds.filter(fund => fund.id !== fundId);
            setFunds(updatedFunds);
        }
    };

    const handleOnScrollTo = (): void => {
        myAcolinDataRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const updateSelectedFund = useCallback(
        (newFund: Fund) => {
            if (!selectedFund) {
                console.error("No fund is selected!");
                return;
            }
            const updatedFunds = funds.map(fund => {
                if (fund["id"] === selectedFund.id) {
                    return newFund;
                } else if (fund.children) {
                    const childIndex = fund.children.findIndex(child => child.id === selectedFund.id);
                    if (childIndex !== -1) {
                        fund.children[childIndex] = newFund;
                    }
                }
                return fund;
            });
            setFunds(updatedFunds);
            setSelectedFund(newFund);
        },
        [selectedFund, funds, setFunds],
    );

    const onFundClick = useCallback((fund: Fund) => {
        setSelectedFund(fund);
    }, []);

    const onCreate = useCallback(
        (data: CreateData, openModal = false) => {
            const { fundType, name, parent } = data;
            if (openModal) {
                setCreateNew({ show: true, fundType, parent });
                return;
            }

            const newFundId: string = uuid.v4();
            if (fundType === FundType.SINGLE_FUND) {
                const newFund: Fund = { fundType, id: newFundId, name, details: initialSingleFund };
                setFunds([...funds, newFund]);
                setSelectedFund(newFund);
            } else if (fundType === FundType.UMBRELLA) {
                const newFund: Fund = { fundType, id: newFundId, name, children: [], details: initialUmbrellaFund };
                setFunds([...funds, newFund]);
                setSelectedFund(newFund);
            } else if (fundType === FundType.SUBFUND) {
                const newParentIndex = funds.findIndex(fund => fund.fundType === FundType.UMBRELLA && fund.id === parent.id);
                const isParentCreated = newParentIndex !== -1;

                const newParent: Fund = isParentCreated
                    ? funds[newParentIndex]
                    : { fundType: FundType.UMBRELLA, id: parent.id, name: parent.name, children: [], details: initialUmbrellaFund };
                const newSubFund: Fund = { fundType, id: newFundId, name, details: initialSubFund };
                newParent.children.push(newSubFund);

                const updatedNewFunds = isParentCreated ? funds.map((fund, i) => (i === newParentIndex ? newParent : fund)) : [...funds, newParent];
                setFunds(updatedNewFunds);
                setCreateNew(initialCreateNew);
                setSelectedFund(newSubFund);
                return;
            }
            setCreateNew(initialCreateNew);
        },
        [funds],
    );

    const allUmbrellas: Parent[] = useMemo(() => {
        const newUmbrellas = funds.filter(fund => fund.fundType === FundType.UMBRELLA);
        return newUmbrellas.map(umbrella => ({ id: umbrella.id, name: umbrella.name }));
    }, [funds]);

    useEffect(() => {
        document.body.style.overflow = createNew.show ? "hidden" : "initial";
    }, [createNew]);

    return (
        <div>
            <div className={`${baseClass}__layoutWrapper`}>
                <NewFundsView
                    onScrollTo={handleOnScrollTo}
                    funds={funds}
                    onCreate={onCreate}
                    onFundClick={onFundClick}
                    selectedFund={selectedFund}
                    onFundDelete={deleteFund}
                />
                <FundDetails fund={selectedFund} updateFund={updateSelectedFund} />
            </div>
            {createNew.show && (
                <CreateDialog
                    oldUmbrellaFunds={oldUmbrellaFunds}
                    fundType={createNew.fundType}
                    parent={createNew.parent}
                    onCreate={onCreate}
                    onClose={(): void => setCreateNew(initialCreateNew)}
                    parentList={allUmbrellas}
                />
            )}
        </div>
    );
};

export default StepFunds;
