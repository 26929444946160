import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Page, { Title } from "@components/containers/Page";
import { Button, Icon } from "@components/common";

import "./NotFound.scss";

const baseClass = "acl-page-404";

const NotFound: React.FC<{}> = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => navigate(-1); // TO-DO Double check this

    return (
        <Page className={baseClass}>
            <Title>Page Not Found</Title>
            <p className={`${baseClass}__details`}>
                No match for <code>{location.pathname}</code> route.
            </p>
            <Button fill="outlined" onClick={goBack} className={`${baseClass}__back`} data-testid="back-button">
                <Icon name="arrow-left-1" spacing="right" className={`${baseClass}__icon`} />
                Back
            </Button>
        </Page>
    );
};

export default NotFound;
