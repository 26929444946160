import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { emailValidator, passwordMultiValidator, repeatPasswordValidator } from "@src/common/util";
import { RECAPTCHA_KEY, shouldDisableCaptcha } from "@src/common/config";
import axios from "@src/common/http";
import { Button, Card, CardContent, Icon } from "@components/common";

import "./Signup.scss";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "@components/common/LabeledInput";

const baseClass = "acl-page-auth";

const initialForm = {
    email: "",
    password: "",
    repeatPassword: "",
};

const Signup: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const recaptchaRef = React.useRef(null);
    const {
        control,
        handleSubmit,
        watch,
        trigger,
        formState: { isDirty, isValid, errors },
    } = useForm({
        defaultValues: initialForm,
        mode: "onChange",
        shouldUnregister: true,
    });

    const onSubmit = async ({ email, password }, e?: React.SyntheticEvent): Promise<void> => {
        e.preventDefault();
        setSubmitting(true);

        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const response = await axios.post(`/signup`, {
            email,
            password,
            recaptcha,
        });
        const { status } = response;

        if (status === 201) {
            setSubmitting(false);
            navigate("/signup-successful");
        } else {
            setSubmitting(false);
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        }
    };

    const newPasswordValue = watch("password");

    useEffect(() => {
        trigger("repeatPassword");
    }, [newPasswordValue]);

    return (
        <main className={`${baseClass} ${baseClass}--signup`}>
            <form onSubmit={handleSubmit(onSubmit)} className={`${baseClass}__form`}>
                <h1>Client Portal</h1>
                <Card className={`${baseClass}__content`}>
                    <CardContent>
                        <h3 className={`${baseClass}__title`}>Create New User Profile</h3>
                        <Link className={`${baseClass}__signup-link`} to="/sign-in">
                            Already signed up?
                        </Link>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: { value: true, message: "This field is required" }, validate: emailValidator }}
                            disabled={submitting}
                            render={({ field }) => {
                                const { ref, ...rest } = field;
                                return <LabeledInput error={errors.email?.message} label="Your email address" {...rest} />;
                            }}
                        />
                        <div className={`${baseClass}__password-wrapper`}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: { value: true, message: "This field is required" }, validate: passwordMultiValidator }}
                                disabled={submitting}
                                render={({ field }) => {
                                    const { ref, ...rest } = field;
                                    return (
                                        <LabeledInput
                                            error={errors.password?.message}
                                            type={showPassword ? "text" : "password"}
                                            label="Choose a password"
                                            {...rest}
                                        />
                                    );
                                }}
                            />
                            <Icon
                                name={showPassword ? "view" : "view-off"}
                                className={`${baseClass}__view-icon`}
                                color="secondary"
                                onClick={(): void => setShowPassword(!showPassword)}
                            />
                        </div>
                        <div className={`${baseClass}__password-wrapper`}>
                            <Controller
                                name="repeatPassword"
                                control={control}
                                rules={{ validate: (value: string) => repeatPasswordValidator(value, newPasswordValue) }}
                                disabled={submitting}
                                render={({ field }) => {
                                    const { ref, ...rest } = field;
                                    return (
                                        <LabeledInput
                                            error={errors.repeatPassword?.message}
                                            type={showRepeatPassword ? "text" : "password"}
                                            label="Confirm New Password"
                                            {...rest}
                                        />
                                    );
                                }}
                            />
                            <Icon
                                name={showRepeatPassword ? "view" : "view-off"}
                                className={`${baseClass}__view-icon`}
                                color="secondary"
                                onClick={(): void => setShowRepeatPassword(!showRepeatPassword)}
                            />
                        </div>
                        <div className={`${baseClass}__info`}>
                            <Icon name="question-circle" className={`${baseClass}__infoIcon`} />
                            <span>Check your inbox to verify your user profile</span>
                        </div>
                        <div className={`${baseClass}__actionButtons`}>
                            <Button themeColor="primary" type="submit" disabled={!isDirty || !isValid || submitting}>
                                {submitting && <Icon name="loading" spacing="right" />}
                                Sign Up
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
            </form>
        </main>
    );
};

export default Signup;
