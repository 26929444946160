import React, { useContext, ReactNode } from "react";
import { ToastContainer, toast } from "react-toastify";

import "./NotificationContext.scss";

interface INotification {
    message: string | React.ReactNode;
    type?: "success" | "warning" | "error";
    timeout?: number | false;
    autoWidth?: boolean;
}

type ContextValue = {
    sendNotification: (notification: INotification) => void;
    clearNotifications: () => void;
};

const initialValue: ContextValue = {
    sendNotification: (): void => {},
    clearNotifications: (): void => {},
};

export const NotificationContext = React.createContext<ContextValue>(initialValue);
NotificationContext.displayName = "NotificationContext";
interface Props {
    children?: ReactNode;
}

export const NotificationContextProvider: React.FC<Props> = ({ children }) => {
    function clearNotifications(): void {
        toast.dismiss();
    }

    function sendNotification(notification: INotification): void {
        const { message, type = "success", timeout = 8000, autoWidth = false } = notification;
        toast(message, {
            type,
            autoClose: timeout,
            position: "bottom-center",
            theme: "colored",
            className: autoWidth ? "autoWidth" : null,
        });
    }

    return (
        <NotificationContext.Provider value={{ sendNotification, clearNotifications }}>
            {children}
            <ToastContainer />
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = (): ContextValue => useContext(NotificationContext);
