import React from "react";

import { emailValidator } from "@src/common/util";
import { ERole, IUser } from "@src/common/types";
import { Button, Icon, ErrorMessage } from "@components/common";

import "./UserForm.scss";
import Modal from "@components/common/Modal";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "@components/common/LabeledInput";
import LabeledSelect from "@components/common/LabeledSelect";

const baseClass = "acl-user-form";

interface IProps {
    user?: IUser;
    isDisabled?: boolean;
    onClose(): void;
    onEdit?(roles: string[]): void;
    onCreate?(user: IUser): void;
}

const initialValues: IUser = {
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    roles: [],
};

const roles = [
    { name: "Admin", code: ERole.PortalAdmin },
    { name: "Viewer", code: ERole.PortalViewer },
    { name: "Data Manager", code: ERole.DataManager },
    { name: "Document Manager", code: ERole.DocumentManager },
    { name: "Commission Manager", code: ERole.CommissionManager },
];

interface IRole {
    code: string;
    name: string;
}

export const mapUserRoles = (user: IUser): IRole[] =>
    user.roles.map(role => ({ ...role, name: role.name.replace("Portal ", "") })).sort((a, b) => (a.name > b.name ? 1 : -1));

const UserForm: React.FC<IProps> = ({ user, isDisabled, onEdit, onCreate, onClose = (): void => undefined }) => {
    const transformedUser: IUser = user
        ? {
              ...user,
              roles: mapUserRoles(user),
          }
        : null;

    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm({
        defaultValues: transformedUser || initialValues,
        mode: "onChange",
        shouldUnregister: true,
    });

    const handleEditRoles = (dataItem: IUser): void => {
        const roles = dataItem?.roles?.map(role => role.code) || [];
        onEdit(roles);
    };

    const title = transformedUser
        ? `Edit roles for user ${transformedUser.firstName ? transformedUser.firstName : ""} ${transformedUser.lastName ? transformedUser.lastName : ""}`
        : "Request New User";

    const sortedRoles = roles.sort((a, b) => (a.name > b.name ? 1 : -1));

    return (
        <Modal className={`${baseClass}`} title={title} onClose={onClose} width={transformedUser ? "600px" : "430px"}>
            {!transformedUser ? (
                <form onSubmit={handleSubmit(onCreate)} className={`${baseClass}__form`}>
                    <div className={`${baseClass}__field-group`}>
                        <Controller
                            name="firstName"
                            control={control}
                            rules={{ required: { value: true, message: "This field is required" } }}
                            render={({ field }) => {
                                const { ref, ...rest } = field;
                                return <LabeledInput error={errors.firstName?.message} label="First Name" {...rest} />;
                            }}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            rules={{ required: { value: true, message: "This field is required" } }}
                            render={({ field }) => {
                                const { ref, ...rest } = field;
                                return <LabeledInput error={errors.lastName?.message} label="Family Name" {...rest} />;
                            }}
                        />
                    </div>
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: { value: true, message: "This field is required" }, validate: emailValidator }}
                        render={({ field }) => {
                            const { ref, ...rest } = field;
                            return <LabeledInput error={errors.email?.message} label="Email Address" {...rest} />;
                        }}
                    />
                    <Controller
                        name="jobTitle"
                        control={control}
                        rules={{ required: { value: true, message: "This field is required" } }}
                        render={({ field }) => {
                            const { ref, ...rest } = field;
                            return <LabeledInput error={errors.jobTitle?.message} label="Position" {...rest} />;
                        }}
                    />
                    <Controller
                        name="roles"
                        control={control}
                        rules={{ required: { value: true, message: "This field is required" } }}
                        render={({ field }) => {
                            const { ref, ...rest } = field;
                            return (
                                <LabeledSelect
                                    options={sortedRoles}
                                    label="Assign Roles"
                                    error={errors.roles?.message}
                                    getOptionLabel={(option: any) => option.name}
                                    getOptionValue={(option: any) => option.code}
                                    isMulti
                                    {...rest}
                                />
                            );
                        }}
                    />
                    <div className={`${baseClass}__actions`}>
                        <Button themeColor="primary" type="submit" disabled={!isDirty || !isValid || isDisabled}>
                            {isDisabled && <Icon name="loading" spacing="right" />}
                            Submit Request
                        </Button>
                    </div>
                </form>
            ) : (
                <form onSubmit={handleSubmit(handleEditRoles)} className={`${baseClass}__form`}>
                    <Controller
                        name="roles"
                        control={control}
                        rules={{ required: { value: true, message: "This field is required" } }}
                        render={({ field }) => {
                            const { ref, ...rest } = field;
                            return (
                                <LabeledSelect
                                    options={sortedRoles}
                                    label="Assign Roles"
                                    error={errors.roles?.message}
                                    getOptionLabel={(option: any) => option.name}
                                    getOptionValue={(option: any) => option.code}
                                    isMulti
                                    {...rest}
                                />
                            );
                        }}
                    />
                    <div className={`${baseClass}__actions`}>
                        <Button themeColor="primary" type="submit" disabled={!isDirty || !isValid || isDisabled}>
                            {isDisabled && <Icon name="loading" spacing="right" />}
                            Apply
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
};

export default UserForm;
