import React, { ComponentType } from "react";
import { GroupBase, ValueContainerProps, components } from "react-select";

type Option = {
    value: string;
    label: string;
};

const ValueContainer: ComponentType<ValueContainerProps<Option, true, GroupBase<Option>>> = props => {
    const { children, ...otherProps } = props;
    const [rawValues, input] = children as any;

    let values = rawValues;
    if (Array.isArray(values)) {
        values = values.length === 1 ? values[0] : `${values.length} selected`;
    }

    return (
        <components.ValueContainer {...otherProps}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

export default ValueContainer;
