import React, { useEffect, useState } from "react";
import { Button, Card, CardContent } from "@components/common";
import "../InitiateOnboarding.scss";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DistribitionForms, ExternalDistribution, PrincipalDistributorDetails } from "./types";

const baseClass = "acl-page-initiate-onboarding";

type Props = {
    state: DistribitionForms;
    setState: React.Dispatch<React.SetStateAction<DistribitionForms>>;
};

const initialPrincipalDistribution: PrincipalDistributorDetails = {
    principalDistributorName: "",
    principalWebsite: "",
};
const initialExternalDistributions: ExternalDistribution[] = [
    {
        externalDistributorSubdistributorPartnerName: "",
        externalWebsite: "",
    },
];

const StepDistribution: React.FC<Props> = ({ state, setState }) => {
    const { principalDistribution, externalDistributions, isPrincipalDistributor, isExternalDistributtor } = state;
    const [isHideButton, setIsHideButton] = useState(false);

    const handlePrincipal = (e): void => {
        const isPrincipalDistributor = e.value === "Yes";
        setState(prevState => {
            if (isPrincipalDistributor) {
                if (prevState.principalDistribution) {
                    return { ...prevState, isPrincipalDistributor };
                } else {
                    return { ...prevState, isPrincipalDistributor, principalDistribution: { ...initialPrincipalDistribution } };
                }
            } else {
                return { ...prevState, isPrincipalDistributor };
            }
        });
    };

    const handleDistributed = (e): void => {
        const isExternalDistributtor = e.value === "Yes";
        setState(prevState => {
            if (isExternalDistributtor) {
                if (prevState.externalDistributions) {
                    return { ...prevState, isExternalDistributtor };
                } else {
                    return { ...prevState, isExternalDistributtor, externalDistributions: [...initialExternalDistributions] };
                }
            } else {
                return { ...prevState, isExternalDistributtor };
            }
        });
    };

    const updatePrincipalDistributionForm = (fieldKey, newValue) => {
        setState(prevState => {
            return { ...prevState, principalDistribution: { ...principalDistribution, [fieldKey]: newValue } };
        });
    };

    const updateExternalDistributionsForms = (fieldKey, newValue: string, index: number): void => {
        setState(prevState => {
            const newExternalDistributions = prevState.externalDistributions?.map((item, i) => {
                if (i !== index) {
                    return item;
                }
                return { ...item, [fieldKey]: newValue };
            });
            return { ...prevState, externalDistributions: newExternalDistributions };
        });
    };

    const addMoreDistributors = (): void => {
        const newExternalDistributon: ExternalDistribution = {
            externalDistributorSubdistributorPartnerName: "",
            externalWebsite: "",
        };

        setState(prevState => ({ ...prevState, externalDistributions: [...prevState.externalDistributions, newExternalDistributon] }));
    };

    const removeDistributors = (arrayIndex): void => {
        if (externalDistributions?.length > 1) {
            setState(prevState => ({ ...prevState, externalDistributions: prevState.externalDistributions.filter((item, index) => index !== arrayIndex) }));
        }
    };

    useEffect(() => {
        if (externalDistributions?.length > 1) {
            setIsHideButton(true);
        } else {
            setIsHideButton(false);
        }
    }, [externalDistributions]);

    return (
        <Card className={`${baseClass}__overview`}>
            <CardContent>
                <div className={`${baseClass}__indentedContent`}>
                    <h2>Entity distributing your fund(s) in Switzerland</h2>
                    <div className={`${baseClass}__contentWrapper`}>
                        <h4>Principal Distributor of the fund</h4>
                        <div className={`${baseClass}__radioWrapper`}>
                            <RadioButton
                                name="turn"
                                label="Yes"
                                value="Yes"
                                className={`${baseClass}__radioButtonYes`}
                                onChange={handlePrincipal}
                                checked={isPrincipalDistributor}
                            />
                            <RadioButton
                                name="turn"
                                label="No"
                                value="No"
                                className={`${baseClass}__radioButtonNo`}
                                onChange={handlePrincipal}
                                checked={isPrincipalDistributor === false}
                            />
                        </div>
                    </div>
                    {isPrincipalDistributor && (
                        <>
                            <Card className={`${baseClass}__distributorContactForm`}>
                                <CardContent>
                                    <div className={`${baseClass}__cardContent`}>
                                        <div className={`${baseClass}__stepDistributionFields`}>
                                            <label>Principal Distributor Name</label>
                                            <Input
                                                value={principalDistribution?.principalDistributorName}
                                                onChange={(e): void => updatePrincipalDistributionForm("principalDistributorName", e.value)}
                                            />
                                        </div>
                                        <div className={`${baseClass}__stepDistributionFields`}>
                                            <label>Website</label>
                                            <Input
                                                value={principalDistribution?.principalWebsite}
                                                onChange={(e): void => updatePrincipalDistributionForm("principalWebsite", e.value)}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </>
                    )}
                    <div className={`${baseClass}__contentWrapper`}>
                        <h4>External Distribution Partner(s)</h4>
                        <div className={`${baseClass}__radioWrapper`}>
                            <RadioButton
                                name="providerDistributed"
                                label="Yes"
                                value="Yes"
                                className={`${baseClass}__radioButtonYes`}
                                onChange={handleDistributed}
                                checked={isExternalDistributtor}
                            />
                            <RadioButton
                                name="providerDistributed"
                                label="No"
                                value="No"
                                className={`${baseClass}__radioButtonNo`}
                                onChange={handleDistributed}
                                checked={isExternalDistributtor === false}
                            />
                        </div>
                    </div>

                    {isExternalDistributtor &&
                        externalDistributions?.map((externalDistributionForms, arrayIndex) => {
                            return (
                                <div key={arrayIndex} className={`${baseClass}__distributionFormsWrapper`}>
                                    <Card className={`${baseClass}__distributorContactForm`} key={arrayIndex}>
                                        <CardContent>
                                            <div className={`${baseClass}__cardContent`}>
                                                <div className={`${baseClass}__stepDistributionFields`}>
                                                    <label>External Distributor/Subdistributor Partner Name</label>
                                                    <Input
                                                        value={externalDistributionForms?.externalDistributorSubdistributorPartnerName}
                                                        onChange={(e): void =>
                                                            updateExternalDistributionsForms(
                                                                "externalDistributorSubdistributorPartnerName",
                                                                e.value,
                                                                arrayIndex,
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className={`${baseClass}__stepDistributionFields`}>
                                                    <label>Website</label>
                                                    <Input
                                                        value={externalDistributionForms?.externalWebsite}
                                                        onChange={(e): void => updateExternalDistributionsForms("externalWebsite", e.value, arrayIndex)}
                                                    />
                                                </div>
                                                {isHideButton && (
                                                    <Button fill="outlined" onClick={(): void => removeDistributors(arrayIndex)}>
                                                        Remove
                                                    </Button>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            );
                        })}
                    {isExternalDistributtor && (
                        <div className={`${baseClass}__addMoreButton`}>
                            <Button themeColor="primary" onClick={(): void => addMoreDistributors()}>
                                Add another
                            </Button>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default StepDistribution;
