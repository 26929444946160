import Button from "@components/common/Button";
import React, { ReactNode } from "react";
import Modal from "../Modal";

type IProps = {
    shouldOpen: boolean;
    title?: string;
    children: ReactNode;
    onClose(): void;
    onConfirm(): void;
    closeButtonText?: string;
    confirmButtonText?: string;
};

const baseClass = "acl-page-initiate-onboarding";

const ConfirmationDialog: React.FC<IProps> = ({
    shouldOpen,
    children,
    title,
    onClose = (): void => undefined,
    onConfirm = (): void => undefined,
    closeButtonText = "Close",
    confirmButtonText = "Confirm",
}) => {
    return (
        shouldOpen && (
            <Modal onClose={onClose} className={`${baseClass}__dialog`}>
                <div>
                    <h3>{title}</h3>
                    {children}
                </div>
                <div className={`${baseClass}__actions`}>
                    <Button themeColor="primary" onClick={onConfirm}>
                        {confirmButtonText}
                    </Button>
                    <Button themeColor="secondary" onClick={onClose}>
                        {closeButtonText}
                    </Button>
                </div>
            </Modal>
        )
    );
};

export default ConfirmationDialog;
