import Page, { Title } from "@components/containers/Page";
import { Card, CardContent, CardHeader } from "@components/common";
import React, { useEffect, useState } from "react";
import axios from "@src/common/http";
import "./FundRepresentation.scss";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT } from "@src/common/config";
import { Link } from "react-router-dom";

const baseClass = "acl-page-fund-representation";

const FundRepresentation: React.FC = () => {
    const [hasNewFunds, setHasNewFunds] = useState<boolean>(true);
    const [hasUpdateDocuments, setHasUpdateDocuments] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        Promise.all([
            axios.get(`${API_ENDPOINT}/rls-wizard`, { params: { isSubmit: false, isCancelled: false } }),
            axios.get(`${API_ENDPOINT}/rls-wizard`, { params: { isSubmit: false, isCancelled: false, isUpdateDocuments: true } }),
        ])
            .then(responses => {
                const { data: newFundsData } = responses[0];
                const { data: updateDocumentsData } = responses[1];
                setHasNewFunds(!!newFundsData);
                setHasUpdateDocuments(!!updateDocumentsData);
                setIsLoading(false);
            })
            .catch(error => console.error("Error fetching data: ", error));
    }, [hasNewFunds]);

    return !isLoading ? (
        <Page>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Fund Representation Switzerland</Title>
            </header>
            <Card>
                <div className={`${baseClass}__content`}>
                    <h2>Request onboarding of your funds for Swiss Fund Representation or provide updated fund documents for existing funds.</h2>
                    <CardHeader>
                        <h2 className={`${baseClass}__bigBold`}>Choose the process</h2>
                    </CardHeader>
                    <CardContent>
                        <div className={`${baseClass}__actions`}>
                            <Card className={`${baseClass}__action`} onClick={() => navigate(`/initiate-onboarding`)}>
                                <CardHeader>
                                    <h3 className={`${baseClass}__bigBold`}>{hasNewFunds ? "Continue your work" : "New Funds Onboarding"}</h3>
                                </CardHeader>
                                <CardContent>Initiate onboarding for Swiss representation</CardContent>
                            </Card>
                            <Card className={`${baseClass}__action`} onClick={() => navigate(`/update-documents`)}>
                                <CardHeader>
                                    <h3 className={`${baseClass}__bigBold`}>{hasUpdateDocuments ? "Continue your work" : "Update Fund Documents"}</h3>
                                </CardHeader>
                                <CardContent>
                                    Provide Acolin with up to date documents such as PR, PRPs, Articles/Fund regulations, AR, SAR, shareholder notices, etc.
                                </CardContent>
                            </Card>
                        </div>
                        <p>
                            You are new to our Fund Representation offering or want to learn more about it?
                            <br />
                            See{" "}
                            <Link className={`${baseClass}__link-password`} to="/user-guidelines">
                                User Guidelines
                            </Link>{" "}
                            or reach out to us at <Link to={`mailto: fundsregulationsch@acolin.com`}>fundsregulationsch@acolin.com</Link>
                        </p>
                    </CardContent>
                </div>
            </Card>
        </Page>
    ) : null;
};

export default FundRepresentation;
