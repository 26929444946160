import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { RECAPTCHA_KEY, shouldDisableCaptcha } from "@src/common/config";
import { emailValidator } from "@src/common/util";
import axios from "@src/common/http";
import { Button, Card, CardContent, Icon } from "@components/common";

import "./ForgotPassword.scss";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "@components/common/LabeledInput";

const baseClass = "acl-page-auth";

const ForgotPassword: React.FC<{}> = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const recaptchaRef = React.useRef(null);
    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm({
        defaultValues: { email: "" },
        mode: "onChange",
        shouldUnregister: true,
    });

    async function onSubmit(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();
        setSubmitting(true);

        // Wait for recaptcha to finish validation before submitting the form
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const response = await axios.post(`/request-reset-password`, { ...formData, recaptcha });
        const { status } = response;

        if (status === 200) {
            setSubmitted(true);
        } else {
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        }

        setSubmitting(false);
    }

    function handleBack(): void {
        setSubmitted(false);
        navigate("/home");
    }

    return (
        <main className={`${baseClass} ${baseClass}--request-password`}>
            <form onSubmit={handleSubmit(onSubmit)} className={`${baseClass}__form`}>
                <h1>Client Portal</h1>
                {submitted && (
                    <Card className={`${baseClass}__content`}>
                        <CardContent>
                            <h3 className={`${baseClass}__title`}>Reset Password</h3>
                            <p>We have sent you a reset link. Please check your inbox.</p>
                            <div className={`${baseClass}__actions`}>
                                <Button data-testid="back-button" themeColor="primary" type="submit" onClick={handleBack}>
                                    Back to Sign In
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                )}
                {!submitted && (
                    <Card className={`${baseClass}__content`}>
                        <CardContent>
                            <h3 className={`${baseClass}__title`}>Reset Password</h3>
                            <p className={`${baseClass}__signup-link`}>
                                or
                                <Link className={`${baseClass}__link`} to="/sign-in">
                                    Sign In to your account
                                </Link>
                            </p>
                            <Controller
                                name="email"
                                control={control}
                                rules={{ required: { value: true, message: "This field is required" }, validate: emailValidator }}
                                disabled={submitting}
                                render={({ field }) => {
                                    const { ref, ...rest } = field;
                                    return <LabeledInput error={errors.email?.message} label="Email Address" {...rest} />;
                                }}
                            />
                            <div className={`${baseClass}__info`}>
                                <Icon name="question-circle" />
                                <p>We’ll send a reset password link to this email address if it matches a valid Acolin Client Portal user profile.</p>
                            </div>
                            <div className={`${baseClass}__actionButtons`}>
                                <Button themeColor="primary" type="submit" disabled={!isDirty || !isValid || submitting}>
                                    {submitting && <Icon name="loading" spacing="right" />}
                                    Send
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                )}
                {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
            </form>
        </main>
    );
};

export default ForgotPassword;
