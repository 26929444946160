import React from "react";
import Select, { Props } from "react-select";

import { ErrorMessage, FieldWrapper, Label, ValueContainer } from "@components/common";
export interface LabeledSelectProps extends Props {
    label?: string;
    options: any;
    readonly?: boolean;
    disabled?: boolean;
    customFilter?: any;
    className?: string;
    value?: any;
    width?: string;
    error?: string;
    onChange?: (e: any) => void;
}

const LabeledSelect: React.FC<LabeledSelectProps> = ({ label, error, readonly = false, disabled = false, customFilter, width, className, ...props }) => {
    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: (state.isSelected || state.isFocused || state.isActive) && "#d89f5a",
            ":active": {
                backgroundColor: "#d89f5a",
            },
            ":hover": {
                backgroundColor: "rgba(216, 159, 90, 0.9)",
            },
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            paddingTop: "1px",
            paddingBottom: "1px",
            paddingLeft: "10px",
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            padding: "0px",
        }),
        clearIndicator: (provided: any) => ({
            ...provided,
            padding: "6px",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "6px",
        }),
        multiValue: (provided: any) => ({
            ...provided,
            maxWidth: "85%",
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: 99999,
        }),
        menuPortal: (provided: any) => ({
            ...provided,
            zIndex: 99999,
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            border: state.isDisabled && !readonly ? provided.border : "1px solid #d89f5a",
            background: state.isDisabled && !readonly ? provided.background : "transparent",
            display: "flex",
            fontSize: "14px",
            fontWeight: "400",
            borderRadius: "20px",
            minHeight: "35px",
        }),
    };

    return (
        <FieldWrapper width={width} className={className}>
            {label && <Label>{label}</Label>}
            <Select
                styles={customStyles}
                defaultValue={props.options.find((option: any) => option.value === props.value)}
                isDisabled={readonly || disabled}
                onChange={(e: any) => props.onChange(e)}
                options={props.options}
                hideSelectedOptions={false}
                closeMenuOnSelect={!props.isMulti}
                components={{ ValueContainer }}
                menuPosition="fixed"
                {...props}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </FieldWrapper>
    );
};

export default LabeledSelect;
