import classNames from "classnames";
import React, { CSSProperties, ReactNode } from "react";

import styles from "./Card.module.scss";

interface Props {
    children?: ReactNode;
    className?: string;
    onClick?(e: any): void;
    style?: CSSProperties | undefined;
}

export const Card: React.FC<Props> = ({ children, className, onClick, style }) => (
    <div className={classNames(styles.card, className)} style={style} onClick={onClick}>
        {children}
    </div>
);

export const CardHeader: React.FC<Props> = ({ children, className }) => <div className={classNames(styles.cardHeader, className)}>{children}</div>;

export const CardContent: React.FC<Props> = ({ children, className }) => <div className={classNames(styles.cardContent, className)}>{children}</div>;

export const CardFooter: React.FC<Props> = ({ children, className }) => <div className={classNames(styles.cardFooter, className)}>{children}</div>;
