import React, { useState, useCallback, useMemo } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import * as dateFns from "date-fns";
import { useQuery } from "@apollo/client";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import { Button, LoadingPanel, Card, CardContent, CardHeader } from "@components/common";

import "./NavHistory.scss";
import { GET_SHARE_CLASS_NAVS } from "@src/common/graphql";
import LabeledSelect from "@components/common/LabeledSelect";
import { TableColumnType, TableHeader } from "@src/common/types";

const baseClass = "acl-share-class-nav-history";

type HistoryProps = {
    isin: string;
};

type FilterOption = {
    name: string;
    value: string;
};

interface XLSXData {
    id: number;
    isin: string;
    nav: number;
    navCurrency: string;
    validFrom: string;
    validTo: string;
}

interface IState {
    series: Array<{ name: string; data: number[] }>;
    xlsxData: XLSXData[];
    minDate: string;
    showChart: boolean;
}

const initialState: IState = {
    series: [
        {
            name: "",
            data: [],
        },
    ],
    xlsxData: [],
    minDate: "",
    showChart: false,
};

const today = new Date();
const oneYearRange = {
    start: dateFns.subYears(today, 1).valueOf(),
    end: today.valueOf(),
};

const options: ApexOptions = {
    chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        toolbar: {
            show: false,
        },
    },
    colors: ["#7d8f80"],
    dataLabels: {
        enabled: false,
    },
    markers: {
        size: 0,
    },
    xaxis: {
        type: "datetime",
        tickAmount: 6,
        min: oneYearRange.start,
        max: oneYearRange.end,
    },
    tooltip: {
        x: {
            format: "dd MMM yyyy",
        },
    },
    stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        width: 2,
        dashArray: 0,
    },
    fill: {
        type: "solid",
        colors: ["#7d8f80"],
        opacity: 0.2,
    },
};

export const getStartDate = (selection: string, endDate: Date = new Date()): Date => {
    switch (selection) {
        case "one_month":
            return dateFns.subMonths(endDate, 1);
        case "three_months":
            return dateFns.subMonths(endDate, 3);
        case "ytd":
            return dateFns.startOfYear(endDate);
        case "one_year":
            return dateFns.subYears(endDate, 1);
        case "two_years":
            return dateFns.subYears(endDate, 2);
        case "three_years":
            return dateFns.subYears(endDate, 3);
        case "five_years":
            return dateFns.subYears(endDate, 5);
        default:
            return dateFns.subYears(endDate, 1);
    }
};

const updateChartRange = (selection: string): void => {
    const endDate = new Date();
    const startDate = getStartDate(selection);
    ApexCharts.exec("area-datetime", "zoomX", startDate.valueOf(), endDate.valueOf());
};

const filterOptions: FilterOption[] = [
    { name: "1 month", value: "one_month" },
    { name: "3 months", value: "three_months" },
    { name: "YTD", value: "ytd" },
    { name: "1 year", value: "one_year" },
    { name: "2 years", value: "two_years" },
    { name: "3 years", value: "three_years" },
    { name: "5 years", value: "five_years" },
];

const columns: TableHeader<any>[] = [
    { label: "Id", field: "id" },
    { label: "Isin", field: "isin" },
    { label: "Nav", field: "nav" },
    { label: "Valid From", field: "validFrom" },
    { label: "Valid To", field: "validTo" },
];

const NavHistory: React.FC<HistoryProps> = ({ isin }) => {
    const [selection, setSelection] = useState<FilterOption>({ name: "1 year", value: "one_year" });
    const [state, setState] = useState<IState>(initialState);

    const rows = useMemo(() => state.xlsxData.map(item => columns.map(col => item[col.field])), [state.xlsxData]);

    const exportToExcel = useCallback(async () => {
        const workbook = new Workbook();
        const sheet = workbook.addWorksheet("Export");

        sheet.properties.defaultColWidth = 20;
        sheet.addTable({
            name: "table",
            ref: "A1",
            headerRow: true,
            style: {
                theme: "TableStyleMedium11",
                showRowStripes: true,
            },
            columns: columns.map(col => ({ name: col.label })),
            rows,
        });

        const buffer = await workbook.xlsx.writeBuffer();

        const excelBlob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(excelBlob, `${isin}_Acolin_NAV_history.xlsx`);
    }, [state.xlsxData]);

    const { loading } = useQuery(GET_SHARE_CLASS_NAVS, {
        variables: { isin },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            if (data?.shareClassNAVs?.length > 0) {
                const { generatedData, xlsxFormatted } = data.shareClassNAVs.reduce(
                    (acc, item: XLSXData) => {
                        acc.generatedData.push([Date.parse(item.validFrom), item.nav]);
                        acc.xlsxFormatted.push({
                            id: item.id,
                            isin: item.isin,
                            nav: item.nav,
                            validFrom: dateFns.format(new Date(item.validFrom), "MM/dd/yyyy"),
                            validTo: dateFns.format(new Date(item.validTo), "MM/dd/yyyy"),
                        });
                        return acc;
                    },
                    { generatedData: [], xlsxFormatted: [] },
                );

                setState(prevState => ({
                    ...prevState,
                    series: [
                        {
                            name: data?.shareClassNAVs?.[0]?.navCurrency,
                            data: generatedData,
                        },
                    ],
                    xlsxData: xlsxFormatted,
                    showChart: true,
                }));
            }
        },
    });

    const updateData = (timeline: FilterOption): void => {
        setSelection(timeline);
        if (state.showChart) {
            updateChartRange(timeline.value);
        }
    };

    const showData = (): React.ReactElement =>
        state.series[0].data.length > 0 ? (
            <div>
                <Chart options={options} series={state.series} type="area" height={350} />
                <div className={`${baseClass}__button-list`}>
                    {filterOptions.map(filter => (
                        <button
                            key={filter.value}
                            id={filter.value}
                            onClick={(): void => updateData(filter)}
                            className={selection.name === filter.name ? "active" : ""}
                        >
                            {filter.name}
                        </button>
                    ))}
                </div>
                <div className={`${baseClass}__filter-mobile`}>
                    <LabeledSelect
                        label="Show NAV History for:"
                        options={filterOptions}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.value}
                        value={selection}
                        onChange={(e): void => updateData(e)}
                    />
                </div>
            </div>
        ) : (
            <div className={`${baseClass}__empty-wrapper`}>
                <div>N/A</div>
            </div>
        );

    return (
        <div className={`${baseClass}`}>
            <Card className={`${baseClass}__card`}>
                <CardHeader className={`${baseClass}__header`}>
                    <h3>NAV History</h3>
                    <div>
                        <Button fill="outlined" disabled={!state.xlsxData.length} onClick={exportToExcel}>
                            Export .xlsx
                        </Button>
                    </div>
                </CardHeader>
                <CardContent>
                    {loading ? (
                        <div className={`${baseClass}__loading-wrapper`}>
                            <LoadingPanel />
                        </div>
                    ) : (
                        showData()
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default NavHistory;
