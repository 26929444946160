import React from "react";

import styles from "./LabeledInput.module.scss";
import FieldWrapper from "../FieldWrapper";
import Label from "../Label/Label";
import Input from "../Input";
import { InputProps } from "../Input/types";
import { Tooltip } from "react-tooltip";
import Icon from "../Icon";
import ErrorMessage from "../ErrorMessage";
export interface LabeledInputProps extends InputProps {
    label: string;
    className?: string;
    tooltip?: string;
    error?: string;
    onChange?: (e: any) => void;
}

const LabeledInput: React.FC<LabeledInputProps> = ({ label, error, className, tooltip, ...props }) => (
    <FieldWrapper className={className}>
        <Label>
            {label}
            {tooltip && (
                <a data-tooltip-id="labelTooltip" data-tooltip-place="right" data-tooltip-content={tooltip}>
                    <Icon className={styles.tooltip} name="question-circle" />
                </a>
            )}
        </Label>
        <Input {...props} className={props.readOnly ? styles.readOnly : ""} />
        <Tooltip className={styles.labelTooltip} id="labelTooltip" />
        {error && <ErrorMessage>{error}</ErrorMessage>}
    </FieldWrapper>
);

export default LabeledInput;
