import React, { useEffect, useState } from "react";
import sortBy from "lodash/sortBy";
import { useQuery } from "@apollo/client";

import { LoadingPanel, FilterInput, Card, CardContent, CardHeader } from "@components/common";

import DistributionMap from "./DistributionMap";
import geoData from "./world-50m.json";
import "./Distribution.scss";
import { GET_SHARE_CLASS_REGISTRATIONS } from "@src/common/graphql";

const baseClass = "acl-share-class-details-distribution";

type Props = {
    isin: string;
};

export type Country = {
    NAME: string;
    NAME_LONG: string;
    ABBREV: string;
    FORMAL_EN: string;
    ISO_A2: string;
    ISO_A3: string;
    CONTINENT: string;
    REGION_UN: string;
    SUBREGION: string;
    legal: string;
    marketing: string;
    treeViewExpanded: boolean;
};

const GEOGRAPHIES = geoData.objects.ne_50m_admin_0_countries.geometries;

function expandValue(value: "yes" | "res" | "no"): string {
    const normalizedValue = value?.toLowerCase();
    switch (normalizedValue) {
        case "yes":
            return "Yes";
        case "res":
            return "Restricted";
        case "no":
            return "No";
        default:
            return "N/A";
    }
}

function buildTreeViewDataset(
    countries,
    filter,
): Array<{
    ISO_A2: string;
    text: string;
    expanded: boolean;
    items: Array<{ text: string }>;
}> {
    const lowerCaseFilter = filter.toLowerCase();
    return countries
        ?.filter(country => country.NAME.toLowerCase().startsWith(lowerCaseFilter))
        ?.map(country => ({
            ISO_A2: country.ISO_A2,
            text: country.NAME,
            expanded: country.treeViewExpanded,
            items: [{ text: `Marketing Distribution: ${expandValue(country.marketing)}` }, { text: `Legal Registration: ${expandValue(country.legal)}` }],
        }));
}

function initializeCountries(registrations, continent): Country[] {
    if (!registrations) {
        return [];
    }

    let countries: Country[] = GEOGRAPHIES.map(country => {
        const ISO_A2 = country.properties.ISO_A2;
        const marketing = registrations[`marketing${ISO_A2}`];
        const legal = registrations[`legal${ISO_A2}`];
        return {
            ...country.properties,
            treeViewExpanded: false,
            marketing,
            legal,
        };
    }).filter(country => country.CONTINENT === continent);

    countries = sortBy(countries, ["NAME"]);

    return countries;
}

const Distribution: React.FC<Props> = ({ isin }) => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [selectedContinent, setSelectedContinent] = useState("Europe");
    const [mapDataset, setMapDataset] = useState<Record<string, string | number[] | object>>(null);
    const [countryFilter, setCountryFilter] = useState("");
    const [mapLoader, setMapLoader] = useState(false);
    const treeViewDataset = buildTreeViewDataset(countries, countryFilter);

    const changeContinent = (continent): void => {
        setMapLoader(true);
        setSelectedContinent(continent);
        setTimeout(() => {
            setMapLoader(false);
        }, 300);
    };

    const NavItem: React.FC<{ continent: string }> = ({ continent }): React.ReactElement => (
        <li onClick={(): void => changeContinent(continent)} className={continent === selectedContinent ? `${baseClass}__active_nav` : ""}>
            {continent}
        </li>
    );

    const { loading, data } = useQuery(GET_SHARE_CLASS_REGISTRATIONS, {
        variables: { isin },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            setMapDataset(geoData);
            const countries = initializeCountries(data.shareClassRegistrations, selectedContinent);
            setCountries(countries);
        },
    });

    useEffect(() => {
        setCountries(initializeCountries(data?.shareClassRegistrations, selectedContinent));
    }, [selectedContinent]);

    return (
        <div className={`${baseClass}`}>
            {loading ? (
                <div className={`${baseClass}__loading-wrapper`}>
                    <LoadingPanel />
                </div>
            ) : (
                <Card className={`${baseClass}__card`}>
                    <CardHeader>
                        <h3>Distribution</h3>
                    </CardHeader>
                    <CardContent className={`${baseClass}__card-body`}>
                        <div className={`${baseClass}__header`}>
                            <FilterInput
                                placeholder={"Filter..."}
                                onChange={(filter: string): void => {
                                    setCountryFilter(filter);
                                }}
                            />
                            <nav className={`${baseClass}__header__nav`}>
                                <ul>
                                    <NavItem continent={"Europe"} />
                                    <NavItem continent={"Asia"} />
                                    <NavItem continent={"South America"} />
                                </ul>
                            </nav>
                        </div>
                        <div className={`${baseClass}__content`}>
                            <div className={`${baseClass}__countries-wrapper`}>
                                {treeViewDataset.map(element => (
                                    <details key={element.ISO_A2}>
                                        <summary>
                                            <span>{element.text}</span>
                                        </summary>
                                        <div className={`${baseClass}__countries-details`}>
                                            <span>{element.items[0].text}</span>
                                            <span>{element.items[1].text}</span>
                                        </div>
                                    </details>
                                ))}
                            </div>
                            {mapDataset && !mapLoader ? (
                                <DistributionMap mapDataset={mapDataset} registrations={data?.shareClassRegistrations} continent={selectedContinent} />
                            ) : (
                                <div className={`${baseClass}__loading-wrapper`}>
                                    <LoadingPanel />
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default Distribution;
