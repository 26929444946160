import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./FieldWrapper.module.scss";

interface Props {
    children?: ReactNode;
    className?: string;
    width?: string;
}

const FieldWrapper: React.FC<Props> = ({ children, className, width }) => (
    <div className={classNames(styles.fieldWrapper, className)} style={{ width: width || null }}>
        {children}
    </div>
);

export default FieldWrapper;
