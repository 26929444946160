import { ForgotPassword, Login, Logout, ResetPassword, Signup, SignupSuccessful, VerifyEmail } from "@pages/Authentication";
import PendingPasswordChange from "@pages/Authentication/PendingPasswordChange";
import FundRepresentation, { InitiateOnboarding, UpdateDocuments } from "@pages/FundRepresentation";
import {
    Home,
    Profile,
    Notifications,
    UserManagement,
    Funds,
    DistributionDashboard,
    DistributionNetwork,
    DataServices,
    ShareClassDetails,
    ComplianceDashboard,
    StaticData,
    RegulatoryData,
    CommissionData,
    FundDocuments,
    PointsOfContact,
    Glossary,
    FAQs,
    UserGuidelines,
    ReleaseNotes,
} from "@pages/index";
import { shouldShowComplianceDashboard } from "@src/common/config";

export type AppRoute =
    | {
          path: `${"/"}${string}`;
          title: string;
          isPublic?: boolean;
          isHidden?: boolean;
          component: React.ComponentType;
          isNew?: boolean;
          isBeta?: boolean;
      }
    | {
          path: `${"http://" | "https://"}${string}`;
          title: string;
          isPublic?: boolean;
          isHidden?: boolean;
          component?: never;
          isNew?: boolean;
          isBeta?: boolean;
      };

const authRoutes: AppRoute[] = [
    { title: "Sign In", path: "/sign-in", component: Login, isPublic: true, isHidden: true },
    { title: "Forgot Password", path: "/forgot-password", component: ForgotPassword, isPublic: true, isHidden: true },
    { title: "Reset Password", path: "/password-reset/:token", component: ResetPassword, isPublic: true, isHidden: true },
    { title: "Verify Email", path: "/verify-email/:token", component: VerifyEmail, isPublic: true, isHidden: true },
    { title: "Signup", path: "/signup", component: Signup, isPublic: true, isHidden: true },
    { title: "Signup Successful", path: "/signup-successful", component: SignupSuccessful, isPublic: true, isHidden: true },
    { title: "Logout", path: "/logout", component: Logout, isPublic: true, isHidden: true },
    { title: "Pending Password Change", path: "/pending-password-change", component: PendingPasswordChange, isPublic: true, isHidden: true },
];

const home: AppRoute = { title: "Home", path: "/home", component: Home, isHidden: true };

const accountRoutes: AppRoute[] = [
    { title: "Profile", path: "/profile", component: Profile },
    { title: "Notifications", path: "/notifications", component: Notifications },
    { title: "User Management", path: "/user-management", component: UserManagement },
];

const distributorsPagePath = "/distribution-network";
const myAcolinRoutes: AppRoute[] = [
    { title: "Funds", path: "/funds", component: Funds },
    { title: "Distribution Dashboard", path: "/distribution-dashboard", component: DistributionDashboard },
    { title: "Distribution Network", path: distributorsPagePath, component: DistributionNetwork },
    { title: "Data Services", path: "/data-services", component: DataServices },
    { title: "Share Class Details", path: "/share-classes/:isin/*", component: ShareClassDetails, isHidden: true },
    { title: "Fund Representation CH", path: "/fund-representation-ch", component: FundRepresentation },
    { title: "Initiate Onboarding", path: "/initiate-onboarding", component: InitiateOnboarding, isHidden: true },
    { title: "Update Documents", path: "/update-documents", component: UpdateDocuments, isHidden: true },
];

if (shouldShowComplianceDashboard) {
    // Getting "Distribution Network" page index to make sure that "Compliance Dashboard" goes after it
    const distributorsPageIndex = myAcolinRoutes.findIndex(route => route.path === distributorsPagePath);
    if (distributorsPageIndex !== -1) {
        // Inserting "Compliance Dashboard" page, after "Distribution Network" page
        myAcolinRoutes.splice(distributorsPageIndex + 1, 0, { title: "Compliance Dashboard", path: "/compliance-dashboard", component: ComplianceDashboard });
    }
} else {
    // Getting "Distribution Network" page index to make sure that "Compliance Dashboard" goes after it
    const distributorsPageIndex = myAcolinRoutes.findIndex(route => route.path === distributorsPagePath);
    if (distributorsPageIndex !== -1) {
        // Inserting "Compliance Dashboard" page, after "Distribution Network" page
        myAcolinRoutes.splice(distributorsPageIndex + 1, 0, {
            title: "Compliance Dashboard",
            path: "/compliance-dashboard-6b7f87e6-ca33-4760-a72a-001a10d19a39",
            component: ComplianceDashboard,
            isHidden: true,
        });
    }
}

const fileRoutes: AppRoute[] = [
    { title: "Static Data", path: "/static-data", component: StaticData },
    { title: "Regulatory Data", path: "/regulatory-data", component: RegulatoryData },
    { title: "Commission Data", path: "/commission-data", component: CommissionData },
    { title: "Fund Documents", path: "/fund-documents", component: FundDocuments },
];

const helpRoutes: AppRoute[] = [
    { title: "Acolin Points of Contact", path: "/points-of-contact", component: PointsOfContact },
    { title: "Glossary", path: "/glossary", component: Glossary },
    { title: "FAQ", path: "/faq", component: FAQs },
    { title: "User Guidelines", path: "/user-guidelines", component: UserGuidelines },
];
const releaseNoteRoute: AppRoute = { title: "Release Notes", path: "/release-notes", component: ReleaseNotes };

export const routes = {
    authentication: authRoutes,
    account: accountRoutes,
    file: fileRoutes,
    myAcolin: myAcolinRoutes,
    help: helpRoutes,
    home: home,
    pages: (): AppRoute[] => [home, ...accountRoutes, ...fileRoutes, ...myAcolinRoutes, ...helpRoutes, releaseNoteRoute],
    all: (): AppRoute[] => [...authRoutes, home, ...accountRoutes, ...fileRoutes, ...myAcolinRoutes, ...helpRoutes, releaseNoteRoute],
};

export default routes;
