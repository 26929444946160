import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { passwordMultiValidator, repeatPasswordValidator } from "@src/common/util";
import { RECAPTCHA_KEY, shouldDisableCaptcha } from "@src/common/config";
import axios from "@src/common/http";
import { Button, Card, CardContent, Icon, LoadingPanel } from "@components/common";

import "./ResetPassword.scss";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "@components/common/LabeledInput";

const baseClass = "acl-page-auth";

type ParamTypes = {
    token: string;
};

const ResetPassword: React.FC<{}> = () => {
    const { token } = useParams<ParamTypes>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const recaptchaRef = React.useRef(null);
    const {
        control,
        handleSubmit,
        watch,
        trigger,
        formState: { isDirty, isValid, errors },
    } = useForm({
        defaultValues: { password: "", repeatPassword: "" },
        mode: "onChange",
        shouldUnregister: true,
    });

    const newPasswordValue = watch("password");

    async function onSubmit(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();
        setSubmitting(true);

        // Wait for recaptcha to finish validation before submitting the form
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const response = await axios.post(`/reset-password/${token}`, { ...formData, recaptcha });
        const { status } = response;

        if (status === 200) {
            setSubmitted(true);
        } else {
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        }

        setSubmitting(false);
    }

    async function checkToken(): Promise<void> {
        if (submitted) {
            return;
        }

        setLoading(true);
        const response = await axios.get(`/request-reset-password?token=${token}`);
        setLoading(false);

        if (response.status !== 200) {
            navigate("/sign-in");
        }
    }

    function handleBack(): void {
        setSubmitted(false);
        navigate("/home");
    }

    useEffect(() => {
        checkToken();
    }, []);

    useEffect(() => {
        trigger("repeatPassword");
    }, [newPasswordValue]);

    return (
        <main className={`${baseClass} ${baseClass}--login`}>
            {loading && <LoadingPanel />}
            {!loading && (
                <form onSubmit={handleSubmit(onSubmit)} className={`${baseClass}__form`}>
                    <h1>Reset Password</h1>
                    {submitted && (
                        <Card className={`${baseClass}__content`}>
                            <CardContent>
                                <h3 className={`${baseClass}__title`}>Reset Password</h3>
                                <p>Your password was successfully changed.</p>
                                <div className={`${baseClass}__actions`}>
                                    <Button themeColor="primary" type="submit" onClick={handleBack}>
                                        Back to Sign In
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    )}
                    {!submitted && (
                        <Card className={`${baseClass}__content`}>
                            <CardContent>
                                <h3 className={`${baseClass}__title`}>Reset Password</h3>
                                <div className={`${baseClass}__password-wrapper`}>
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ required: { value: true, message: "This field is required" }, validate: passwordMultiValidator }}
                                        disabled={submitting}
                                        render={({ field }) => {
                                            const { ref, ...rest } = field;
                                            return (
                                                <LabeledInput
                                                    error={errors.password?.message}
                                                    type={showPassword ? "text" : "password"}
                                                    label="Password"
                                                    {...rest}
                                                />
                                            );
                                        }}
                                    />
                                    <Icon
                                        name={showPassword ? "view" : "view-off"}
                                        className={`${baseClass}__view-icon`}
                                        color="secondary"
                                        onClick={(): void => setShowPassword(!showPassword)}
                                    />
                                </div>
                                <div className={`${baseClass}__password-wrapper`}>
                                    <Controller
                                        name="repeatPassword"
                                        control={control}
                                        rules={{
                                            required: { value: true, message: "This field is required" },
                                            validate: (value: string) => repeatPasswordValidator(value, newPasswordValue),
                                        }}
                                        disabled={submitting}
                                        render={({ field }) => {
                                            const { ref, ...rest } = field;
                                            return (
                                                <LabeledInput
                                                    error={errors.repeatPassword?.message}
                                                    type={showRepeatPassword ? "text" : "password"}
                                                    label="Confirm New Password"
                                                    {...rest}
                                                />
                                            );
                                        }}
                                    />
                                    <Icon
                                        name={showRepeatPassword ? "view" : "view-off"}
                                        className={`${baseClass}__view-icon`}
                                        color="secondary"
                                        onClick={(): void => setShowRepeatPassword(!showRepeatPassword)}
                                    />
                                </div>
                                <div className={`${baseClass}__actions`}>
                                    <Button themeColor="primary" type="submit" disabled={!isDirty || !isValid || submitting}>
                                        {submitting && <Icon name="loading" spacing="right" />}
                                        Change Password
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    )}
                    {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                </form>
            )}
        </main>
    );
};

export default ResetPassword;
