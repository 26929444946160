import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./UpdateDocuments.scss";
import Page, { Title } from "@components/containers/Page";
import * as uuid from "uuid";
import { BackToTop, Button, Icon, LoadingPanel } from "@components/common";
import axios from "@src/common/http";
import { API_ENDPOINT } from "@src/common/config";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "@components/common/ConfirmationDialog/ConfirmationDialog";
import { gql, useQuery } from "@apollo/client";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import StepFunds from "./components/StepFunds";
import { Fund, FundType, UmbrellaFiles, SingleFundFiles, SubFundFiles, FundFile } from "../types";
import { getFileListFromFunds, removeFiles, uploadFiles } from "../util";
import { ERole } from "@src/common/types";
import LabeledSelect from "@components/common/LabeledSelect";
import Modal from "@components/common/Modal";

const baseClass = "acl-page-update-documents";

type ShowModal = {
    showCancel: boolean;
    showSubmit: boolean;
};

const initialShowModal: ShowModal = {
    showCancel: false,
    showSubmit: false,
};

type SelectFundProps = {
    umbrellaFunds: any[];
    subFunds: any[];
    singleFunds: any[];
    fundType: string;
    onConfirm: (fundName: string, parentFundName: string) => void;
    onClose?: () => void;
};

const SelectFundDialog: React.FC<SelectFundProps> = ({ fundType, umbrellaFunds, subFunds, singleFunds, onClose = (): void => undefined, onConfirm }) => {
    const [fundName, setFundName] = useState<any>("");

    const fundList = useMemo(() => {
        if (fundType === "Umbrella") {
            return umbrellaFunds;
        } else if (fundType === "Single Fund") {
            return singleFunds;
        } else {
            return subFunds;
        }
    }, [fundType]);

    const parentFundName = useMemo(() => {
        if (fundType === "Subfund") {
            const subfund = subFunds.find(fund => fund.name === fundName.value);
            const parent = umbrellaFunds.find(umbrella => umbrella.id === subfund?.parentId);
            return parent?.name || "";
        }
        return "";
    }, [fundType, fundName]);

    const canSave = useMemo((): boolean => {
        return fundType === FundType.SUBFUND ? fundName.value && parentFundName : fundName.value;
    }, [fundType, fundName, parentFundName]);

    return (
        <Modal className={`${baseClass}`} title="Select Fund" onClose={onClose} width="500px">
            <div className={`${baseClass}__selectFundDialog`}>
                <LabeledSelect
                    label={"Select " + fundType}
                    options={fundList.map(fund => ({ label: fund.name, value: fund.name }))}
                    onChange={(e): void => setFundName(e)}
                />
                <div className={`${baseClass}__buttonWrapper`}>
                    <Button themeColor="primary" disabled={!canSave} onClick={() => onConfirm(fundName.value, parentFundName)}>
                        Select
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

type ShowSelectFund = {
    fundType: FundType;
    show: boolean;
};

const initShowSelectFund: ShowSelectFund = {
    fundType: null,
    show: false,
};

const UpdateDocuments: React.FC = () => {
    const [appUser] = useAppUserContext();
    const { sendNotification } = useNotificationContext();
    const navigate = useNavigate();

    const [selectedFund, setSelectedFund] = useState<Fund>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<ShowModal>(initialShowModal);
    const [showSelectFund, setShowSelectFund] = useState<ShowSelectFund>(initShowSelectFund);
    const [rlsWizardId, setRlsWizardID] = useState<string>("");

    const [umbrellaFunds, setUmbrellaFunds] = useState<any[]>([]);
    const [subFunds, setSubFunds] = useState<any[]>([]);
    const [singleFunds, setSingleFunds] = useState<any[]>([]);

    const [funds, setFunds] = useState<Fund[]>([]);
    const [oldFiles, setOldFiles] = useState<FundFile[]>([]);

    const isDisabled = !appUser.roles.includes(ERole.DocumentManager);

    const initForm = useCallback(async () => {
        const response = await axios.get(`${API_ENDPOINT}/rls-wizard`, { params: { isSubmit: false, isCancelled: false, isUpdateDocuments: true } });

        const { data: onboardingDataDB } = response;
        if (!onboardingDataDB) {
            return;
        }
        const { rlsWizard, files } = onboardingDataDB;
        setOldFiles(files);
        const { funds, _id: rlsWizardId } = rlsWizard;
        const mappedFiles = files.reduce((acc, file: FundFile) => {
            const { fundId, field } = file;
            if (!acc[fundId]) {
                acc[fundId] = {};
            }
            if (!acc[fundId][field]) {
                acc[fundId][field] = [];
            }
            acc[fundId][field].push(file);
            return acc;
        }, {});

        const extendFundWithFiles = fund => {
            const { id } = fund;
            if (!fund.details) {
                fund.details = {};
            }
            fund.details.files = mappedFiles[id] ?? {};
            if (fund.children?.length > 0) {
                fund.children = fund.children.map(child => extendFundWithFiles(child));
            }
            return fund;
        };

        const fundsWithFiles = funds.map(extendFundWithFiles);
        setFunds(fundsWithFiles);
        setRlsWizardID(rlsWizardId);
    }, []);

    const showCancelModal = useCallback(() => {
        setShowModal({ ...initialShowModal, showCancel: true });
    }, []);

    const showSubmitModal = useCallback(() => {
        setShowModal({ ...initialShowModal, showSubmit: true });
    }, []);

    const closeModals = useCallback((): void => {
        setShowModal(initialShowModal);
    }, []);

    const createRlsWizard = async ({ data }) => axios.post(`${API_ENDPOINT}/rls-wizard`, data, { params: { isUpdateDocuments: true } });
    const updateRlsWizard = async ({ data, id }) => axios.put(`${API_ENDPOINT}/rls-wizard/${id}`, data, { params: { isUpdateDocuments: true } });
    const submitRlsWizard = async (id: string) => axios.patch(`${API_ENDPOINT}/rls-wizard/${id}/submit`, {}, { params: { isUpdateDocuments: true } });

    const upsert = useCallback(async (): Promise<string> => {
        const onboardingData = {
            contacts: null,
            payingAgent: null,
            distribution: null,
            funds,
        };
        const files = getFileListFromFunds(funds);
        const filesToUpload = files.filter(file => !file.externalId);
        const filesToRemove = oldFiles.filter(oldFile => !files.find(file => file._id === oldFile._id));

        if (rlsWizardId) {
            await uploadFiles(rlsWizardId, filesToUpload);
            await removeFiles(rlsWizardId, filesToRemove);
            await updateRlsWizard({ data: onboardingData, id: rlsWizardId });
            return rlsWizardId;
        } else {
            const { data } = await createRlsWizard({ data: onboardingData });
            const rlsWizardId = data._id;
            await uploadFiles(rlsWizardId, filesToUpload);
            return rlsWizardId;
        }
    }, [funds, rlsWizardId, oldFiles, appUser]);

    const submit = useCallback(async (): Promise<void> => {
        try {
            setIsLoading(true);
            const wizardId = await upsert();
            await submitRlsWizard(wizardId);
            navigate("/fund-representation-ch");
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Documents update submited",
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [funds, upsert, initForm, rlsWizardId]);

    const save = useCallback(async (): Promise<void> => {
        try {
            setIsLoading(true);
            await upsert();
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Documents update saved",
            });
            await initForm();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [upsert, initForm]);

    const cancel = useCallback(async (): Promise<void> => {
        if (!rlsWizardId) {
            navigate("/fund-representation-ch");
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Documents update cancelled",
            });
            return;
        }
        setIsLoading(true);
        axios
            .patch(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/cancel`)
            .then(response => {
                const { status } = response;
                if (status === 200) {
                    navigate("/fund-representation-ch");
                    sendNotification({
                        timeout: 10000,
                        type: "success",
                        message: "Documents update cancelled",
                    });
                }
            })
            .catch(error => console.error(error))
            .finally(() => setIsLoading(false));
    }, [rlsWizardId]);

    const GET_ALL_FUNDS = gql`
        query GetAllFunds($companyId: Int!) {
            allFunds(companyId: $companyId) {
                funds {
                    id
                    parentId
                    name
                    fundName
                    cooperationPartnerId
                    managingCompanyId
                    fundType
                    domicileCountry
                    legalForm
                    active
                }
                subfunds {
                    id
                    parentId
                    name
                    fundName
                    cooperationPartnerId
                    managingCompanyId
                    fundType
                    domicileCountry
                    legalForm
                    active
                }
                shareClasses {
                    id
                    fundId
                    isin
                    fullShareClassName
                    lifecycle
                    acolinRelevant
                    nav
                    navCurrency
                    navDate
                    cooperationPartnerId
                    name
                    navInfo
                }
            }
        }
    `;

    const { loading } = useQuery(GET_ALL_FUNDS, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            const { funds, subfunds } = data?.allFunds;
            setUmbrellaFunds(funds.filter(fund => fund.fundType === "Umbrella"));
            setSingleFunds(funds.filter(fund => fund.fundType === "Single Fund"));
            setSubFunds(subfunds);
        },
    });

    const addSelectedFund = useCallback(
        (fundName: string, parentFundName: string, fundType: FundType): Fund => {
            const newFundId: string = uuid.v4();
            const umbrellaFiles: UmbrellaFiles = {
                articlesOfAssociationFiles: [],
                authorizedSignatoriesFiles: [],
                latestAnnualReportFiles: [],
                latestProspectusFiles: [],
                latestSemiAnnualReportFiles: [],
                ucitsAttestationFiles: [],
                marketingFiles: [],
                priipsKidsFiles: [],
                supplementFiles: [],
            };
            const subFundFiles: SubFundFiles = {
                marketingFiles: [],
                priipsKidsFiles: [],
                prospectusFiles: [],
                supplementFiles: [],
            };

            const singleFundFiles: SingleFundFiles = {
                articlesOfAssociationFiles: [],
                authorizedSignatoriesFiles: [],
                latestAnnualReportFiles: [],
                latestProspectusFiles: [],
                latestSemiAnnualReportFiles: [],
                marketingFiles: [],
                priipsKidsFiles: [],
                ucitsAttestationFiles: [],
            };

            if (parentFundName) {
                const existingParentFund = funds.find(fund => fund.name === parentFundName);
                if (!existingParentFund) {
                    const newParentFundId = uuid.v4();
                    const newChildFund = { id: newFundId, name: fundName, fundType: FundType.SUBFUND, details: { files: subFundFiles } };
                    const newParentFund = {
                        id: newParentFundId,
                        name: parentFundName,
                        fundType: FundType.UMBRELLA,
                        details: { files: umbrellaFiles },
                        children: [newChildFund],
                    };
                    setFunds(prevFunds => [...prevFunds, newParentFund]);
                    return newChildFund;
                } else {
                    const existingChildFund = existingParentFund.children?.find(child => child.name === fundName);
                    if (!existingChildFund) {
                        const newFund = { id: newFundId, fundType: FundType.SUBFUND, name: fundName, details: { files: subFundFiles } };
                        setFunds(prevFunds =>
                            prevFunds.map(fund => {
                                if (fund.name === parentFundName) {
                                    const existingChildFund = fund.children.find(child => child.name === fundName);
                                    if (!existingChildFund) {
                                        fund.children.push(newFund);
                                    }
                                }
                                return fund;
                            }),
                        );
                        return newFund;
                    } else {
                        return existingChildFund;
                    }
                }
            } else {
                const existingFund = funds.find(fund => fund.name === fundName);
                if (!existingFund) {
                    const newFund: Fund = {
                        id: newFundId,
                        name: fundName,
                        fundType,
                        children: [],
                    };
                    if (fundType === FundType.UMBRELLA) {
                        newFund.details = { files: umbrellaFiles };
                    } else if (fundType === FundType.SINGLE_FUND) {
                        newFund.details = { files: singleFundFiles };
                    }
                    setFunds(prevFunds => [...prevFunds, { ...newFund }]);
                    return newFund;
                } else {
                    return existingFund;
                }
            }
        },
        [funds, setFunds],
    );

    useEffect(() => {
        setIsLoading(true);
        initForm()
            .catch(error => console.error("Error fetching data:", error))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        const handleClickOutside = (event): void => {
            if (event.target.classList.contains("k-overlay")) {
                setShowModal(initialShowModal);
            }
        };

        const { showCancel, showSubmit } = showModal;

        if (showCancel || showSubmit) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return (): void => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showModal]);

    return isLoading || loading ? (
        <LoadingPanel />
    ) : (
        <Page>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Fund Representation Switzerland</Title>
            </header>
            <div className={`${baseClass}__initialOnboardingMenu`}>
                <div className={`${baseClass}__initialOnboardingButtons`}>
                    <Button themeColor="primary" onClick={showCancelModal} className={`${baseClass}__initialOnboardingButton`} disabled={isDisabled}>
                        Cancel
                    </Button>
                    <Button themeColor="primary" onClick={save} className={`${baseClass}__initialOnboardingButton`} disabled={isDisabled}>
                        Save
                    </Button>
                    <Button
                        themeColor="primary"
                        onClick={showSubmitModal}
                        className={`${baseClass}__initialOnboardingButton`}
                        disabled={funds?.length === 0 || isDisabled}
                    >
                        Submit
                    </Button>
                </div>
            </div>
            <StepFunds
                funds={funds}
                setFunds={setFunds}
                onCreate={fundType => setShowSelectFund({ fundType, show: true })}
                selectedFund={selectedFund}
                setSelectedFund={setSelectedFund}
            />

            <ConfirmationDialog
                shouldOpen={showModal.showCancel}
                onClose={closeModals}
                onConfirm={cancel}
                closeButtonText="Discard"
                confirmButtonText="Confirm"
            >
                <div className={`${baseClass}__success-message`}>
                    <p>
                        By cancelling the wizard process, you will lose the loaded information data for this fund document update.
                        <br />
                        If you wish to continue later, you can save your work.
                    </p>
                </div>
            </ConfirmationDialog>
            <ConfirmationDialog
                shouldOpen={showModal.showSubmit}
                onClose={closeModals}
                onConfirm={() => {
                    submit();
                    closeModals();
                }}
                closeButtonText="No"
                confirmButtonText="Yes"
            >
                <div className={`${baseClass}__success-message`}>
                    <div className={`${baseClass}__success-message`}>
                        <p>Confirm you are ready to submit your Fund update documents request.</p>
                        <p>
                            <Icon name="notes-book-text" spacing="both" />
                            What happens next? Our Funds Regulation Team will review the submitted information and revert to you via email.
                            <br />
                            Should you have any questions, feel free to reach out to us at{" "}
                            <a href="mailto:fundsregulationsch@acolin.com">fundsregulationsch@acolin.com.</a>
                        </p>
                    </div>
                    <div className={`${baseClass}__confirmMessage`}>
                        <p>
                            <Icon name="notes-book-text" spacing="both" />
                            If you haven't finished the procedure, you can click on Save to save your work and continue where you've left at the later point.
                        </p>
                    </div>
                </div>
            </ConfirmationDialog>
            {showSelectFund.show && (
                <SelectFundDialog
                    fundType={showSelectFund.fundType}
                    umbrellaFunds={umbrellaFunds}
                    singleFunds={singleFunds}
                    subFunds={subFunds}
                    onConfirm={(fundName, parentFundName) => {
                        const newFund = addSelectedFund(fundName, parentFundName, showSelectFund.fundType);
                        setSelectedFund(newFund);
                        setShowSelectFund(initShowSelectFund);
                    }}
                    onClose={() => setShowSelectFund(initShowSelectFund)}
                />
            )}
            <BackToTop />
        </Page>
    );
};

export default UpdateDocuments;
