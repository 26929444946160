import React, { useCallback } from "react";
import Button from "@components/common/Button";

import "./Reload.scss";

const baseClass = "acl-reload";

const Reload: React.FC<{}> = () => {
    const handleClick = useCallback(() => window.location.reload(), []);

    return (
        <div className={`${baseClass}`}>
            <p className={`${baseClass}__message`}>There's a new version of this app!</p>
            <Button themeColor="primary" onClick={handleClick} className={`${baseClass}__button`}>
                Reload
            </Button>
        </div>
    );
};

export default Reload;
