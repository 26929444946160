import React, { useEffect, useState } from "react";

import { repeatPasswordValidator, passwordMultiValidator } from "@src/common/util";
import axios from "@src/common/http";
import { Button, ErrorMessage } from "@components/common";

import "./ChangePassword.scss";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "@components/common/LabeledInput";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";

const baseClass = "acl-change-password";

const initialForm = {
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
};

const ChangePassword: React.FC<{}> = () => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [appUser, _setAppUser] = useAppUserContext();
    const { sendNotification } = useNotificationContext();
    const {
        control,
        handleSubmit,
        watch,
        trigger,
        reset,
        formState: { isDirty, isValid, errors },
    } = useForm({
        defaultValues: initialForm,
        mode: "onChange",
        shouldUnregister: true,
    });

    const newPasswordValue = watch("newPassword");

    const fields = [
        {
            name: "oldPassword",
            label: "Old Password",
        },
        {
            name: "newPassword",
            label: "New Password",
            validate: passwordMultiValidator,
        },
        {
            name: "repeatNewPassword",
            label: "Repeat New Password",
            validate: (value: string) => repeatPasswordValidator(value, newPasswordValue),
        },
    ];

    const onSubmit = async (data: any) => {
        setSubmitting(true);

        const { email } = appUser;
        const { oldPassword, newPassword } = data;
        const response = await axios.post(`/changePassword`, { email, oldPassword, newPassword });
        const { status } = response;

        if (status === 200) {
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Password succesfully changed",
            });
        }

        setSubmitting(false);
        reset(initialForm);
    };

    useEffect(() => {
        trigger("repeatNewPassword");
    }, [newPasswordValue]);

    return (
        <div className={baseClass} data-testid="form">
            <form onSubmit={handleSubmit(onSubmit)}>
                {fields.map((element: any) => (
                    <React.Fragment key={element.name}>
                        <Controller
                            name={element.name}
                            control={control}
                            rules={{ required: { value: true, message: "This field is required" }, validate: element.validate }}
                            disabled={submitting}
                            render={({ field }) => {
                                const { ref, ...rest } = field;
                                return <LabeledInput label={element.label + "*"} {...rest} />;
                            }}
                        />
                        {errors[element.name] && <ErrorMessage>{errors[element.name].message}</ErrorMessage>}
                    </React.Fragment>
                ))}

                <div className={`${baseClass}__actions`}>
                    <Button type="submit" disabled={!isDirty || !isValid || submitting}>
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
