import React, { ReactNode } from "react";

import Button from "@components/common/Button";

import "./PromptDialog.scss";
import Icon from "../Icon";
import Modal from "../Modal";

const baseClass = "acl-user-dialog";

interface IProps {
    title: string;
    children?: ReactNode;
    text?: string;
    confirmText?: string;
    rejectText?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    onReject(): void;
    onConfirm(): void;
}

const PromptDialog: React.FC<IProps> = ({
    onReject = (): void => null,
    onConfirm = (): void => null,
    title,
    text,
    children,
    confirmText = "Yes",
    rejectText = "No",
    isDisabled,
}) => {
    return (
        <Modal className={`${baseClass}`} title={title} onClose={onReject} width="500px">
            {text && <p>{text}</p>}
            {children}
            <div className={`${baseClass}__actions`}>
                <Button fill="outlined" onClick={onReject} disabled={isDisabled}>
                    {rejectText}
                </Button>
                <Button themeColor="primary" onClick={onConfirm} disabled={isDisabled}>
                    {isDisabled && <Icon name="loading" spacing="right" />}
                    {confirmText}
                </Button>
            </div>
        </Modal>
    );
};

export default PromptDialog;
