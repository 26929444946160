import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardContent } from "@components/common";

import "./PendingPasswordChange.scss";
import { getTokenExpiry } from "@src/common/token";
import { shouldDisableWorkers } from "@src/common/config";

const baseClass = "acl-page-auth";

const PendingPasswordChange: React.FC<{}> = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!shouldDisableWorkers && getTokenExpiry()) {
            navigate("/home");
        }
    }, []);

    return (
        <main className={`${baseClass}`}>
            <div className={`${baseClass}__form`}>
                <h1>Client Portal</h1>
                <Card className={`${baseClass}__content`}>
                    <CardContent>
                        <h3 className={`${baseClass}__title`}>Password Change</h3>
                        <div className={`${baseClass}__textWrapper`}>
                            <p>Your password for Client Portal needs to be updated according to enhanced security standards Acolin has implemented. </p>
                            <p>Please check your inbox as we have just sent you a password reset email.</p>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </main>
    );
};

export default PendingPasswordChange;
