import React, { useEffect, useRef, useState } from "react";
import { publish } from "pubsub-js";
import { NetworkStatus, useLazyQuery } from "@apollo/client";
import classNames from "classnames";

import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { generateMonths, generateQuarters, getFilenameFromContentDisposition } from "@src/common/util";
import axios from "@src/common/http";
import { API_ENDPOINT } from "@src/common/config";
import { ERole, initialTemplate, PSChannel, Template } from "@src/common/types";
import Page, { Title } from "@components/containers/Page";
import { Button, Card, CardHeader, CardContent, CardFooter, BackToTop, Upload as FileUpload, Icon, LoadingPanel } from "@components/common";

import DataLoads from "../_components/DataLoads";
import { IState, initialState } from "./types";
import "./CommissionData.scss";
import { GET_COMMISSION_LOADS, GET_COMMISSION_SETTINGS } from "@src/common/graphql";
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from "react-joyride";
import { useSetState } from "react-use";
import { errors } from "@src/common/errors";
import LabeledSelect from "@components/common/LabeledSelect";
import { differenceInHours, formatDistanceToNow } from "date-fns";

const baseClass = "acl-page-commission-data";

interface TutorialState {
    run: boolean;
    steps: Step[];
}

interface CommissionDataTemplate extends Template {
    validFrom: string;
    year: string;
    quarter?: string;
    month?: string;
    fundGroupId: string;
}

export const initialCommissionTemplate: CommissionDataTemplate = {
    ...initialTemplate,
    validFrom: null,
    year: null,
    fundGroupId: null,
};

const CommissionData: React.FC<{}> = () => {
    const [state, setState] = useState<IState>(initialState);
    const [appUser, _setAppUser] = useAppUserContext();
    const [loadingLoads, setLoadingLoads] = useState<boolean>(true);
    const [hasFundGroups, setHasFundGroups] = useState<boolean>(true);
    const [isMonthlyFrequency, setIsMonthlyFrequency] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [templates, setTemplates] = useState<CommissionDataTemplate[]>([]);
    const [currentTemplate, setCurrentTemplate] = useState<CommissionDataTemplate>(initialCommissionTemplate);
    const { sendNotification } = useNotificationContext();
    const [{ run, steps }, setTutorialState] = useSetState<TutorialState>({
        run: false,
        steps: [
            {
                title: "Upload ACT template",
                content: "Let us guide you through delivering your new ACT file",
                locale: { skip: <strong aria-label="skip">Close</strong> },
                placement: "center",
                target: "body",
            },
            {
                content: "Click here to select quarter",
                placement: "bottom",
                styles: {
                    options: {
                        width: 300,
                    },
                },
                target: ".quarter_selector",
                title: "Select quarter",
            },
            {
                content: "Click here to select the Fund group you want to update ACT file for",
                placement: "bottom",
                styles: {
                    options: {
                        width: 300,
                    },
                },
                target: ".fund_group_selector",
                title: "Select fund group",
            },
            {
                content: "Click here to download the latest ACT for that criteria",
                placement: "bottom",
                styles: {
                    options: {
                        width: 300,
                    },
                },
                target: ".download_button_selector",
                title: "Download template",
            },
            {
                content: "Once you edit and save your file, upload it back to Acolin here",
                placement: "top",
                target: ".upload_selector",
                title: "Upload files",
            },
        ],
    });

    const getFundData = async (isRefresh = false): Promise<void> => {
        isRefresh && setIsRefreshing(true);
        const response = await axios.get(`/fund-data-generation`);
        const { status, data } = response;

        if (status === 200) {
            setTemplates(data.commission);
        }
        setTimeout(() => {
            setIsRefreshing(false);
        }, 2000);
    };

    const generateFundData = async () => {
        const period = isMonthlyFrequency ? `month=${state.selectedMonth.month}` : `quarter=${state.selectedQuarter.quarter}`;
        const year = isMonthlyFrequency ? state.selectedMonth.year : state.selectedQuarter.year;
        const response = await axios.post(`/fund-data-generation?type=commission&year=${year}&${period}&fundGroupId=${state.selectedFundGroupDownload.id}`);
        const { status } = response;

        if (status === 200) {
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Generation in progress.",
            });
            getFundData();
        }
    };

    const helpers = useRef<StoreHelpers>();

    const setHelpers = (storeHelpers: StoreHelpers): void => {
        helpers.current = storeHelpers;
    };

    const [getCommissionLoads, { loading: loadingGetLoads, error: errorLoads, data: dataLoads, refetch: refetchLoads, networkStatus: networkStatusLoads }] =
        useLazyQuery(GET_COMMISSION_LOADS, {
            variables: { companyId: appUser.companyId },
            notifyOnNetworkStatusChange: true,
        });

    useEffect(() => {
        setLoadingLoads(networkStatusLoads === NetworkStatus.refetch || loadingGetLoads);
    }, [loadingGetLoads, networkStatusLoads]);

    const onAddHandle = (file): void => {
        setState(prevState => ({
            ...prevState,
            uploadUrl: state.xlsxUploadUrl,
            shouldShowFundGroups: file === ".xlsx",
        }));
    };

    // TODO create button to trigger start tour
    // const handleClickStartTour = (event: React.MouseEvent<HTMLElement>): void => {
    //     event.preventDefault();

    //     setTutorialState({
    //         run: true,
    //     });
    // };

    const handleJoyrideCallback = (data: CallBackProps): void => {
        const { status } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status) || data.action === "close") {
            setTutorialState({ run: false });
        }
        if (data.action === "close") {
            helpers.current.skip();
        }
    };

    const onRemoveHandle = (): void => setState(prevState => ({ ...prevState, shouldShowFundGroups: false }));

    const onProgressHandle = (): void => onRemoveHandle();

    const [getCommissionSettings, { loading: loadingCommissionSettings }] = useLazyQuery(GET_COMMISSION_SETTINGS, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
        onCompleted: ({ commissionSettings }) => {
            if (!commissionSettings?.actFundGroups.length) {
                setHasFundGroups(false);
            }
            const fundGroups = commissionSettings?.actFundGroups ?? [];
            const firstFundGroup = fundGroups[0] ?? null;
            setIsMonthlyFrequency(commissionSettings?.actFrequency === "m" ? true : false);
            setState(prevState => ({
                ...prevState,
                fundGroups,
                selectedFundGroupUpload: firstFundGroup,
                selectedFundGroupDownload: firstFundGroup,
            }));
        },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
            return;
        }
        getCommissionLoads().catch(error => console.error(error));
        getCommissionSettings().catch(error => console.error(error));
        getFundData();
    }, []);

    const handleDownload = (): void => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
            return;
        }

        setDownloading(true);
        const selectedMonth = isMonthlyFrequency ? state.selectedMonth.month : "";
        const selectedQuarter = !isMonthlyFrequency ? state.selectedQuarter.quarter : "";
        const selectedYear = isMonthlyFrequency ? state.selectedMonth.year : state.selectedQuarter.year;
        const params = { fundGroupId: state.selectedFundGroupDownload.id, type: "commission", year: selectedYear };
        if (selectedMonth) {
            params["month"] = selectedMonth;
        } else {
            params["quarter"] = selectedQuarter;
        }
        axios
            .get(`${API_ENDPOINT}/fund-data`, { responseType: "blob", params })
            .then(response => {
                if (response.status !== 200) {
                    setDownloading(false);
                    return;
                }
                // Get original file name from header
                const header = response.headers["content-disposition"];
                const filename = getFilenameFromContentDisposition(header, "act-template.xlsx");

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                setDownloading(false);
            })
            .catch(error => {
                setDownloading(false);
                console.error(error);
            });
    };

    const findCurrentTemplate = () => {
        if (templates.length > 0 && state.selectedFundGroupDownload?.id) {
            const result = templates.find(template => {
                if (isMonthlyFrequency) {
                    return (
                        Number(template.year) === state.selectedMonth.year &&
                        Number(template.month) === state.selectedMonth.month &&
                        Number(template.fundGroupId) === state.selectedFundGroupDownload.id
                    );
                } else {
                    return (
                        Number(template.year) === state.selectedQuarter.year &&
                        Number(template.quarter) === state.selectedQuarter.quarter &&
                        Number(template.fundGroupId) === state.selectedFundGroupDownload.id
                    );
                }
            });
            setCurrentTemplate(result || initialCommissionTemplate);
        }
    };

    useEffect(() => {
        generateQuarters().then(quarters => {
            setState(prevState => ({
                ...prevState,
                quarters,
                selectedQuarter: quarters[0],
            }));
        });
        generateMonths().then(months => {
            setState(prevState => ({
                ...prevState,
                months,
                selectedMonth: months[0],
            }));
        });
    }, []);

    useEffect(() => {
        if (state.selectedFundGroupUpload) {
            const url = `${API_ENDPOINT}/fund-data?type=commission&fundGroupId=${state.selectedFundGroupUpload.id}`;
            setState(prevState => ({
                ...prevState,
                xlsxUploadUrl: url,
                uploadUrl: url,
            }));
        }
    }, [state.selectedFundGroupUpload]);

    useEffect(() => {
        findCurrentTemplate();
    }, [state.selectedFundGroupDownload, state.selectedQuarter, state.selectedMonth, templates]);

    const uploadClasses = classNames(`${baseClass}__upload-wrapper`, state.shouldShowFundGroups && `${baseClass}--fund-groups-upload`);

    return (
        <Page className={`${baseClass}`}>
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                hideCloseButton
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                getHelpers={setHelpers}
                styles={{
                    options: {
                        zIndex: 10000,
                        primaryColor: "#7d8f80",
                    },
                }}
            />
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>
                    Commission Data
                    <a data-tooltip-id="tooltip" data-tooltip-content={"Acolin Commission Template (ACT)​"} data-tooltip-place="top">
                        <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                    </a>
                </Title>
            </header>

            {loadingCommissionSettings ? (
                <Card className={`${baseClass}__static__placeholder`}>
                    <CardContent className={`${baseClass}__static__content`}>
                        <LoadingPanel />
                    </CardContent>
                </Card>
            ) : hasFundGroups ? (
                <>
                    {appUser.roles.includes(ERole.CommissionManager) && (
                        <div className={`${baseClass}__static`}>
                            <Card className={`${baseClass}__static__upload`}>
                                <CardHeader className={`${baseClass}__static__header`}>
                                    <h3 className={`${baseClass}__static__title`}>Upload</h3>
                                </CardHeader>
                                <CardContent className={`${baseClass}__static__content`}>
                                    <div className={uploadClasses}>
                                        <FileUpload
                                            url={state.uploadUrl}
                                            extensions={[".xlsx"]}
                                            multiple={false}
                                            dataType="commission"
                                            onSuccess={(): void => {
                                                if (!appUser.companyId) {
                                                    publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
                                                    return;
                                                }
                                                refetchLoads();
                                            }}
                                            onRemoveHandle={onRemoveHandle}
                                            onAddHandle={onAddHandle}
                                            onProgressHandle={onProgressHandle}
                                            tutorialClass={"upload_selector"}
                                        />
                                        {state.shouldShowFundGroups && (
                                            <div className={`${baseClass}__select-wrapper`}>
                                                <LabeledSelect
                                                    label="Select fund group"
                                                    options={state.fundGroups}
                                                    getOptionLabel={(option: any) => option.name}
                                                    getOptionValue={(option: any) => option.id}
                                                    value={state.selectedFundGroupUpload}
                                                    onChange={(e): void => setState(prevState => ({ ...prevState, selectedFundGroupUpload: e }))}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                            <Card className={`${baseClass}__static__download`}>
                                <CardHeader className={`${baseClass}__static__header`}>
                                    <h3 className={`${baseClass}__static__title-top`}>Download</h3>
                                    <h3 className={`${baseClass}__static__title`}>Acolin Commission Template (ACT)</h3>
                                </CardHeader>
                                <CardContent className={`${baseClass}__static__content`}>
                                    <p>
                                        Generate and download the latest checked and validated Commission Data template file for selected fund group to work on.
                                    </p>
                                    <div className={`${baseClass}__datepicker`}>
                                        <LabeledSelect
                                            label={`Select ${isMonthlyFrequency ? "month" : "quarter"}`}
                                            options={isMonthlyFrequency ? state.months : state.quarters}
                                            width="100%"
                                            getOptionLabel={(option: any) => option.text}
                                            getOptionValue={(option: any) => option.text}
                                            value={isMonthlyFrequency ? state.selectedMonth : state.selectedQuarter}
                                            className={`${baseClass}__datepicker--fieldWrapper`}
                                            onChange={(e): void =>
                                                setState(prevState => ({ ...prevState, [isMonthlyFrequency ? "selectedMonth" : "selectedQuarter"]: e }))
                                            }
                                        />
                                    </div>
                                    <div className={classNames(`${baseClass}__select-wrapper`, "fund_group_selector")}>
                                        <LabeledSelect
                                            label="Select fund group"
                                            options={state.fundGroups.map(option => ({
                                                id: option.id,
                                                name: option.name,
                                            }))}
                                            getOptionLabel={(option: any) => option.name}
                                            getOptionValue={(option: any) => option.id}
                                            value={state.selectedFundGroupDownload}
                                            onChange={(e): void => setState(prevState => ({ ...prevState, selectedFundGroupDownload: e }))}
                                        />
                                    </div>
                                </CardContent>
                                <CardFooter className={`${baseClass}__static__footer`}>
                                    {state.selectedQuarter && state.selectedFundGroupDownload && (
                                        <>
                                            <div className={`${baseClass}__template-details`}>
                                                {currentTemplate.generationInProgress ? (
                                                    <small>
                                                        Your file is currently being generated.
                                                        <br />
                                                        We will notify you shortly to confirm it is ready for download.
                                                        <br />
                                                        Check your email inbox or click refresh to check generating status.
                                                    </small>
                                                ) : currentTemplate.fileGeneratedTimestamp &&
                                                    differenceInHours(new Date(), new Date(currentTemplate.fileGeneratedTimestamp)) < 12 ? (
                                                    <>
                                                        <div>
                                                            <small>
                                                                File name: <b>{currentTemplate.fileName}</b>
                                                            </small>
                                                        </div>
                                                        <small>
                                                            The generated file is available for download within 12 hours.
                                                            <br />
                                                            Generated {formatDistanceToNow(new Date(currentTemplate.fileGeneratedTimestamp))} ago.
                                                        </small>
                                                    </>
                                                ) : (
                                                    <small>
                                                        There is currently no generated template for this period.
                                                        <br />
                                                        Click Generate button to prepare template for download.
                                                    </small>
                                                )}
                                            </div>
                                            <Button
                                                themeColor="primary"
                                                onClick={handleDownload}
                                                disabled={
                                                    downloading ||
                                                    currentTemplate.generationInProgress ||
                                                    differenceInHours(new Date(), new Date(currentTemplate.fileGeneratedTimestamp)) >= 12
                                                }
                                                className={classNames(`${baseClass}__static__footer-button`, "download_button_selector")}
                                            >
                                                {downloading ? (
                                                    <>
                                                        <Icon name="loading" spacing="right" /> Downloading
                                                    </>
                                                ) : (
                                                    "Download"
                                                )}
                                            </Button>
                                            <Button
                                                themeColor="secondary"
                                                onClick={generateFundData}
                                                disabled={currentTemplate.generationInProgress}
                                                className={`${baseClass}__static__footer-button`}
                                            >
                                                {currentTemplate.generationInProgress ? (
                                                    <>
                                                        <Icon name="loading" spacing="right" /> Generating
                                                    </>
                                                ) : (
                                                    "Generate Template"
                                                )}
                                            </Button>
                                            {currentTemplate.generationInProgress && (
                                                <Button
                                                    themeColor="primary"
                                                    onClick={() => getFundData(true)}
                                                    className={`${baseClass}__static__footer-button`}
                                                >
                                                    {isRefreshing ? (
                                                        <>
                                                            <Icon name="loading" spacing="right" /> Refreshing
                                                        </>
                                                    ) : (
                                                        "Refresh"
                                                    )}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </CardFooter>
                            </Card>
                        </div>
                    )}
                    <div className={`${baseClass}__status`}>
                        <Card className={`${baseClass}__status__upload`}>
                            <CardHeader className={`${baseClass}__status__title`}>
                                <h3 className={`${baseClass}__card__title`}>Commission Data Loads</h3>
                                <Button
                                    fill="outlined"
                                    onClick={(): void => {
                                        if (!appUser.companyId) {
                                            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
                                            return;
                                        }
                                        refetchLoads();
                                    }}
                                >
                                    Refresh
                                </Button>
                            </CardHeader>
                            <CardContent className={classNames(`${baseClass}__status__content`, `${baseClass}__status__content-loads`)}>
                                <DataLoads dataset={dataLoads?.loads} loading={loadingLoads} isCommissionData={true} />
                            </CardContent>
                        </Card>
                    </div>
                </>
            ) : (
                <Card className={`${baseClass}__static__placeholder`}>
                    <CardContent>
                        <p className={`${baseClass}__empty`}>You have not signed up to Acolin’s Distribution Network Management service.</p>
                        <p>
                            To find out more, please visit our{" "}
                            <a href="https://acolin.com" target="_blank">
                                website
                            </a>{" "}
                            or contact your Acolin representative {appUser.pointsOfContact.crm?.fullName} at{" "}
                            <a href={`mailto:${appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}`}>
                                {appUser.pointsOfContact.crm?.email || "crmteam@acolin.com"}
                            </a>
                            .
                        </p>
                    </CardContent>
                </Card>
            )}
            <BackToTop />
        </Page>
    );
};

export default CommissionData;
