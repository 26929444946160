import classNames from "classnames";
import React, { ReactNode } from "react";

import styles from "./Modal.module.scss";
import { Card, CardContent, CardHeader } from "../Card";

interface Props {
    children?: ReactNode;
    className?: string;
    title?: string;
    visible?: boolean;
    width?: string;
    onClose: () => void;
}

const Modal: React.FC<Props> = ({ children, className, visible = true, title, onClose, width = null }) => (
    <div onClick={onClose} className={classNames(styles.modal, visible && styles.visible, className)}>
        <Card className={styles.modalWrapper} style={{ maxWidth: width }} onClick={(e: any): void => e.stopPropagation()}>
            {title && (
                <CardHeader className={styles.modalHeader}>
                    <h3>{title}</h3>
                    <span className={styles.close} onClick={onClose}>
                        &times;
                    </span>
                </CardHeader>
            )}
            <CardContent className={styles.modalCardContent}>{children}</CardContent>
        </Card>
    </div>
);

export default Modal;
