import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "@apollo/client";

import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { RECAPTCHA_KEY, emailCampaigns, shouldDisableCaptcha } from "@src/common/config";
import { SEND_MAIL_MUTATION, UPDATE_USER_INFORMATION } from "@src/common/graphql";
import { userStatusMap } from "@src/common/consts";
import Page, { Title } from "@components/containers/Page";
import { Button, Chip, Icon, Card, CardHeader, CardContent, ErrorMessage } from "@components/common";

import ChangePassword from "./ChangePassword";
import TwoFactorAuth from "./TwoFactorAuth";
import "./Profile.scss";
import { Controller, useForm } from "react-hook-form";
import LabeledInput from "@components/common/LabeledInput";
import { emailValidator } from "@src/common/util";

const baseClass = "acl-page-profile";

const Profile: React.FC<{}> = () => {
    const recaptchaRef = React.useRef(null);
    const [appUser, setAppUser] = useAppUserContext();
    const { firstName, lastName, email, jobTitle, statusCode } = appUser;
    const { sendNotification } = useNotificationContext();
    const {
        control,
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm({
        defaultValues: { firstName, lastName, email, jobTitle },
        mode: "onChange",
        shouldUnregister: true,
    });

    const [updateUserInformation, { loading, error }] = useMutation(UPDATE_USER_INFORMATION);
    const [sendMail] = useMutation(SEND_MAIL_MUTATION, {
        onCompleted: () => {
            console.warn("Email sent.");
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        },
        onError: error => {
            console.error("Email sending failed: ", error.message);
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        },
    });

    async function onSubmit(formData: Record<string, string>): Promise<void> {
        const userInfo = {
            ...formData,
            email,
        };

        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const message = Object.keys(formData).reduce((acc, key) => {
            const label = ((key: string): string => {
                switch (key) {
                    case "firstName":
                        return "Name";
                    case "lastName":
                        return "Last Name";
                    case "email":
                        return "Email";
                    default:
                        return "Position";
                }
            })(key);
            return `${acc}\n${label}: ${formData[key]}`;
        }, "");

        const sendMailVariables = {
            recaptcha,
            campaign: emailCampaigns.Profile,
            params: {
                Company: appUser.companyName,
                Sender: appUser.email,
                Text: message,
            },
        };

        updateUserInformation({
            variables: { userInfo },
            onCompleted: data => {
                setAppUser({
                    ...appUser,
                    firstName: data?.updateUserInformation?.firstName,
                    lastName: data?.updateUserInformation?.lastName,
                    jobTitle: data?.updateUserInformation?.jobTitle,
                });
                sendNotification({
                    timeout: 6000,
                    type: "success",
                    message: "Your request has been submitted.",
                });
                sendMail({
                    variables: sendMailVariables,
                });
            },
        });
    }

    return (
        <Page className={baseClass}>
            <Title className={`${baseClass}__title`}>Profile</Title>
            <div className={`${baseClass}__static`}>
                <div>
                    <Card className={`${baseClass}__content`}>
                        <CardHeader>
                            {statusCode !== 1 ? (
                                <div className={`${baseClass}__tooltip-container`}>
                                    <h3 className={`${baseClass}__title`}>
                                        {firstName} {lastName}
                                    </h3>
                                    <Chip
                                        name={userStatusMap[statusCode][0]}
                                        appearance="filled"
                                        size="small"
                                        type={userStatusMap[statusCode][1]}
                                        className={`${baseClass}__status`}
                                    />
                                    <a
                                        data-tooltip-id="tooltip"
                                        data-tooltip-place="right"
                                        data-tooltip-content={userStatusMap[statusCode][3] || userStatusMap[statusCode][2]}
                                    >
                                        <Icon className={`${baseClass}__tooltip`} name="question-circle" />
                                    </a>
                                </div>
                            ) : (
                                <h3 className={`${baseClass}__title`}>
                                    {firstName} {lastName}
                                </h3>
                            )}
                        </CardHeader>
                        <CardContent>
                            <form onSubmit={handleSubmit(onSubmit)} className={`${baseClass}__form`}>
                                <div className={`${baseClass}__field-group`}>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        rules={{ required: { value: true, message: "This field is required" } }}
                                        disabled={loading}
                                        render={({ field }) => {
                                            const { ref, ...rest } = field;
                                            return <LabeledInput error={errors.firstName?.message} label="First Name" {...rest} />;
                                        }}
                                    />
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        rules={{ required: { value: true, message: "This field is required" } }}
                                        disabled={loading}
                                        render={({ field }) => {
                                            const { ref, ...rest } = field;
                                            return <LabeledInput error={errors.lastName?.message} label="Family Name" {...rest} />;
                                        }}
                                    />
                                </div>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: { value: true, message: "This field is required" }, validate: emailValidator }}
                                    disabled={loading}
                                    render={({ field }) => {
                                        const { ref, ...rest } = field;
                                        return (
                                            <LabeledInput
                                                label="Email Address"
                                                error={errors.email?.message}
                                                tooltip={`If your email address has changed, please contact your Portal Admin to request a new user under "User Management" or get in touch with your responsible Acolin contact.`}
                                                {...rest}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name="jobTitle"
                                    control={control}
                                    rules={{ required: { value: true, message: "This field is required" } }}
                                    disabled={loading}
                                    render={({ field }) => {
                                        const { ref, ...rest } = field;
                                        return <LabeledInput error={errors.jobTitle?.message} label="Position" {...rest} />;
                                    }}
                                />
                                <div className={`${baseClass}__footer`}>
                                    <div className={`${baseClass}__actions`}>
                                        <Button themeColor="primary" type="submit" disabled={!isDirty || !isValid || loading}>
                                            Request a change
                                        </Button>
                                    </div>
                                    <div className={`${baseClass}__messages`}>
                                        {loading && (
                                            <div className={`${baseClass}__loading`} aria-busy="true">
                                                Please wait... <Icon name="loading" spacing="left" />
                                            </div>
                                        )}
                                        {error && (
                                            <ErrorMessage className={`${baseClass}__error`}>{error.message || error.graphQLErrors?.[0]?.message}</ErrorMessage>
                                        )}
                                    </div>
                                </div>
                                {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                            </form>
                        </CardContent>
                    </Card>

                    <Card className={`${baseClass}__content`}>
                        <CardHeader>
                            <h3 className={`${baseClass}__title`}>Two-Factor Authentication</h3>
                        </CardHeader>
                        <CardContent>
                            <TwoFactorAuth />
                        </CardContent>
                    </Card>

                    <Card className={`${baseClass}__content`}>
                        <CardHeader>
                            <h3 className={`${baseClass}__title`}>Change Password</h3>
                        </CardHeader>
                        <CardContent>
                            <ChangePassword />
                        </CardContent>
                    </Card>
                </div>
            </div>
        </Page>
    );
};

export default Profile;
