import React, { useState, useEffect, useRef, useCallback } from "react";
import { publish } from "pubsub-js";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "@apollo/client";

import { TextArea, RadioButton } from "@progress/kendo-react-inputs";
import { Button, ErrorMessage, Label } from "@components/common";

import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { RECAPTCHA_KEY, emailCampaigns, shouldDisableCaptcha } from "@src/common/config";
import { SEND_MAIL_MUTATION } from "@src/common/graphql";

import "./ActivationForm.scss";
import { PSChannel, SelectOption } from "@src/common/types";
import LabeledSelect from "@components/common/LabeledSelect";
import Modal from "@components/common/Modal";

const baseClass = "acl-activation-form";

interface IProps {
    countriesList: string[];
    selectedCountry?: string;
    onClose(): void;
}

enum Messages {
    CountriesMessage = `Hi there,\n\nI'd like to connect to selected countries.\nPlease get back to me to discuss the matter further.`,
    DPSMessage = `Hi there,\n\nI'd like to connect to the Distribution Partner(s) listed below.\nPlease get back to me to discuss the matter further.\n\n[Please list here the Distribution Partners you want to connect to]`,
}

enum Options {
    ActivateCountries = "activate-countries",
    ActivateDPs = "activate-dps",
}

const ActivationForm: React.FC<IProps> = props => {
    const recaptchaRef = useRef(null);
    const { countriesList, selectedCountry, onClose = (): void => undefined } = props;
    const [appUser, _setAppUser] = useAppUserContext();
    const [selectedValue, setSelectedValue] = React.useState<Options>(Options.ActivateDPs);
    const [selectedCountries, setSelectedCountries] = useState<SelectOption[]>();
    const [message, setMessage] = useState<Messages>(Messages.DPSMessage);
    const { firstName, lastName, companyName, email } = appUser;
    const { sendNotification } = useNotificationContext();

    const [sendMail] = useMutation(SEND_MAIL_MUTATION, {
        onCompleted: () => {
            sendNotification({
                timeout: 6000,
                type: "success",
                message: "Your request has been submitted.",
            });
            !shouldDisableCaptcha && recaptchaRef.current.reset();
            onClose();
        },
        onError: error => {
            publish(PSChannel.Error, error.message);
            !shouldDisableCaptcha && recaptchaRef.current.reset();
            onClose();
        },
    });

    const handleSubmit = async (event): Promise<void> => {
        event.preventDefault();

        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        const userDetails = `Account: ${companyName}\n${firstName ? "Name: " + firstName : ""}\n${lastName ? "Last Name: " + lastName : ""}\nEmail: ${email}`;
        const selectedOption =
            selectedValue === Options.ActivateDPs
                ? "Activation of specific distribution partner"
                : "Activation of additional countries: " + selectedCountries.map(option => option.value).join(", ");

        const sendMailVariables = {
            recaptcha,
            campaign: emailCampaigns.DistributionPartner,
            params: {
                Client: userDetails,
                Text: selectedOption,
                Message: message,
            },
        };

        sendMail({ variables: sendMailVariables });
    };

    const handleChange = useCallback(
        e => {
            setSelectedValue(e.value);
            setMessage(e.value === Options.ActivateCountries ? Messages.CountriesMessage : Messages.DPSMessage);
        },
        [setSelectedValue],
    );

    const changeMessage = (e): void => {
        setMessage(e.value);
    };

    useEffect(() => {
        if (selectedCountry) {
            setSelectedCountries([{ label: selectedCountry, value: selectedCountry }]);
            setSelectedValue(Options.ActivateCountries);
            setMessage(Messages.CountriesMessage);
        } else {
            setMessage(Messages.DPSMessage);
        }
    }, [selectedCountry]);

    return (
        <Modal width="520px" className={`${baseClass}`} title="Request for connecting to a country or partner" onClose={onClose}>
            <form onSubmit={handleSubmit}>
                <Label className={`${baseClass}__label`}>I am interested in connecting to...</Label>
                <div className={`${baseClass}__radio-wrapper`}>
                    <RadioButton
                        name="activationSelection"
                        value={Options.ActivateCountries}
                        checked={selectedValue === Options.ActivateCountries}
                        label="Countries"
                        onChange={handleChange}
                    />
                </div>
                <LabeledSelect
                    options={countriesList.map(option => ({ label: option, value: option }))}
                    placeholder="Select countries"
                    value={selectedCountries}
                    isMulti
                    onChange={(event): void => setSelectedCountries(event)}
                    className={`${baseClass}__countriesInput`}
                />
                {selectedValue === Options.ActivateCountries && !selectedCountries?.length && (
                    <ErrorMessage className={`${baseClass}__error`}>This field is required</ErrorMessage>
                )}
                <div className={`${baseClass}__radio-wrapper`}>
                    <RadioButton
                        name="activationSelection"
                        value={Options.ActivateDPs}
                        checked={selectedValue === Options.ActivateDPs}
                        label="Specific Distribution Partner(s)"
                        onChange={handleChange}
                    />
                </div>
                <Label className={`${baseClass}__label`}>Message</Label>
                <TextArea id="message" name="message" value={message} onChange={changeMessage} className={`${baseClass}__textarea`} />
                <div className={`${baseClass}__actions`}>
                    <Button themeColor="primary" type="submit" disabled={selectedValue === Options.ActivateCountries && !selectedCountries?.length}>
                        Send
                    </Button>
                </div>
                {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
            </form>
        </Modal>
    );
};

export default ActivationForm;
