import React from "react";
import classNames from "classnames";

import styles from "./Input.module.scss";
import { InputProps } from "./types";

const Input: React.FC<InputProps> = ({
    type = "text",
    value,
    id,
    readOnly = false,
    required = false,
    disabled = false,
    hasError = false,
    placeholder,
    className,
    onChange,
    onKeyUp = () => [],
    ...props
}) => {
    return (
        <input
            className={classNames(styles.input, hasError && styles.error, readOnly && styles.readOnly, className)}
            type={type}
            value={value}
            id={id}
            readOnly={readOnly}
            disabled={disabled}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            required={required}
            onKeyUp={e => onKeyUp(e)}
            {...props}
        />
    );
};

export default Input;
