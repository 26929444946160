import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@components/common";
import { CardHeader, CardContent, Card } from "@components/common/Card";
import { Fund, FundType } from "@pages/FundRepresentation/types";
import FundDetails from "./StepFundsDetails";
import classNames from "classnames";
import { Button, Chip } from "@components/common";
import ConfirmationDialog from "@components/common/ConfirmationDialog/ConfirmationDialog";
import "../UpdateDocuments.scss";

const baseClass = "acl-page-update-documents";

type Parent = {
    id: string;
    name: string;
};

type StepFundsProps = {
    funds: Fund[];
    setFunds: (data: Fund[]) => void;
    onCreate: (param: any) => void;
    selectedFund: Fund;
    setSelectedFund: (fund: Fund) => void;
};

type FundsViewProps = {
    funds: Fund[];
    selectedFund?: Fund;
    onCreate: (fundType: FundType) => void;
    onScrollTo?: () => void;
    onFundClick?: (fund: Fund) => void;
    onFundDelete: (fundId: string, parentId?: string) => void;
};

type FundProps = {
    fund: Fund;
    parent?: Fund;
    selectedFund?: Fund;
    onCreate?: (fundType: FundType) => void;
    onFundClick?: (fund: Fund) => void;
    onDelete: (fundId: string, parentId?: string) => void;
};

const FundComponent: React.FC<FundProps> = ({ fund, parent, selectedFund, onFundClick, onDelete }) => {
    const [shouldDeleteModal, setShouldDeleteModal] = useState<boolean>(false);

    // const isUmbrella = fund.fundType === FundType.UMBRELLA;
    const isSubFund = fund.fundType === FundType.SUBFUND;
    const isShareClass = fund.fundType === FundType.SHARECLASS;

    const { fundType, name, id } = fund;

    const isSelected = id === selectedFund?.id;

    const classes = ["fund"];
    if (isSubFund || isShareClass) {
        classes.push("childFund");
    }
    classes.push("newFund");
    if (isSelected) {
        classes.push("selectedFund");
    }

    const mappedClasses = classes.map(item => `${baseClass}__${item}`);
    const hasChildren = fund.children?.length > 0 || false;

    const handleFundClick = (): void => {
        if (!onFundClick) {
            return;
        }
        onFundClick(fund);
    };

    function handleDeleteClick(): void {
        setShouldDeleteModal(true);
    }

    return (
        <div className={hasChildren && `${baseClass}__fundContainer`}>
            <div className={classNames(`${baseClass}__fundLayout`)}>
                <div className={classNames([...mappedClasses])} onClick={handleFundClick}>
                    <Chip name={fundType} type="disabled" size="small" className={classNames(`${baseClass}__chip`)} />
                    {name}
                </div>
                <Icon title="" name="remove-circle" spacing="both" className={`${baseClass}__trash`} onClick={() => handleDeleteClick()} />
                <ConfirmationDialog
                    shouldOpen={shouldDeleteModal}
                    onClose={(): void => {
                        setShouldDeleteModal(false);
                    }}
                    onConfirm={(): void => {
                        onDelete(fund.id, parent?.id);
                    }}
                >
                    <div className={`${baseClass}__success-message`}>Are you sure that you want to discard this fund selection?</div>
                </ConfirmationDialog>
            </div>

            {hasChildren && (
                <div className={classNames(`${baseClass}__childFundContainer`)}>
                    {fund.children?.map(subfund => (
                        <FundComponent
                            key={subfund.id}
                            fund={subfund}
                            parent={fund}
                            onFundClick={onFundClick}
                            selectedFund={selectedFund}
                            onDelete={onDelete}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const NewFundsView: React.FC<FundsViewProps> = ({ funds, selectedFund, onCreate, onScrollTo, onFundClick, onFundDelete }) => (
    <Card className={`${baseClass}__overview`}>
        <CardHeader>
            <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                <div style={{ alignContent: "center", alignItems: "center", margin: "0px auto" }}>
                    <h2 className={`${baseClass}__title`}>Update documents for your existing funds</h2>
                </div>
                <div className={classNames(`${baseClass}__createActionContainer`)}>
                    <Button themeColor="primary" onClick={(): void => onCreate(FundType.SINGLE_FUND)}>
                        Single Fund
                    </Button>
                    <Button themeColor="primary" onClick={(): void => onCreate(FundType.UMBRELLA)}>
                        Umbrella Fund
                    </Button>
                    <Button themeColor="primary" onClick={(): void => onCreate(FundType.SUBFUND)}>
                        Subfund
                    </Button>
                </div>
            </div>
        </CardHeader>
        <CardContent>
            {funds.map(fund => (
                <div className={`${baseClass}__rowFund`}>
                    <div className={`${baseClass}__fundSize`}>
                        <FundComponent
                            key={fund.id}
                            fund={fund}
                            onCreate={onCreate}
                            selectedFund={selectedFund}
                            onDelete={onFundDelete}
                            onFundClick={onFundClick}
                        />
                    </div>
                </div>
            ))}
        </CardContent>
    </Card>
);

type CreateNew = {
    show: boolean;
    fundType: FundType;
    parent: Parent;
};

const initialCreateNew: CreateNew = {
    show: false,
    fundType: null,
    parent: null,
};

const getAllFundIds = (funds: Fund[]): string[] =>
    funds.flatMap(fund => {
        if (fund.children?.length > 0) {
            return [fund.id, ...getAllFundIds(fund.children)];
        } else {
            return [fund.id];
        }
    });

const StepFunds: React.FC<StepFundsProps> = ({ funds, setFunds, onCreate, selectedFund, setSelectedFund }) => {
    const [createNew, _setCreateNew] = useState<CreateNew>(initialCreateNew);

    const myAcolinDataRef = useRef(null);

    const deleteFund = (fundId: string, parentId?: string): void => {
        const isSelectedFundDeleted = selectedFund?.id === fundId;
        const isSelectedFundsParentDeleted = funds.find(fund => fund?.children?.find(child => child?.id === selectedFund?.id));

        if (isSelectedFundDeleted || isSelectedFundsParentDeleted) {
            setSelectedFund(null);
        }

        if (parentId !== undefined) {
            const updatedFunds = funds.map(fund => {
                if (fund.id === parentId) {
                    const updatedChildren = fund?.children?.filter(child => child.id !== fundId);
                    return { ...fund, children: updatedChildren };
                }
                return fund;
            });
            setFunds(updatedFunds);
        } else {
            const updatedFunds = funds.filter(fund => fund.id !== fundId);
            setFunds(updatedFunds);
        }
    };

    const handleOnScrollTo = (): void => {
        myAcolinDataRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const updateSelectedFund = useCallback(
        (newFund: Fund) => {
            if (!selectedFund) {
                console.error("No fund is selected!");
                return;
            }
            const updatedFunds = funds.map(fund => {
                if (fund["id"] === selectedFund.id) {
                    return newFund;
                } else if (fund.children) {
                    const childIndex = fund.children.findIndex(child => child.id === selectedFund.id);
                    if (childIndex !== -1) {
                        fund.children[childIndex] = newFund;
                    }
                }
                return fund;
            });
            setFunds(updatedFunds);
            setSelectedFund(newFund);
        },
        [selectedFund, funds, setFunds],
    );

    const onFundClick = useCallback((fund: Fund) => {
        setSelectedFund(fund);
    }, []);

    useEffect(() => {
        document.body.style.overflow = createNew.show ? "hidden" : "initial";
    }, [createNew]);

    return (
        <div>
            <div className={`${baseClass}__layoutWrapper`}>
                <NewFundsView
                    onScrollTo={handleOnScrollTo}
                    funds={funds}
                    onCreate={onCreate}
                    onFundClick={onFundClick}
                    selectedFund={selectedFund}
                    onFundDelete={deleteFund}
                />
                <FundDetails fund={selectedFund} updateFund={updateSelectedFund} />
            </div>
        </div>
    );
};

export default StepFunds;
