import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FieldWrapper, Label } from "@components/common";

import styles from "./LabeledDatepicker.module.scss";
import classNames from "classnames";

export interface ButtonProps {
    label: string;
    selected: Date;
    readOnly?: boolean;
    wrapperClassName?: string;

    placeholderText?: string;
    onChange?: (e: any) => void;
}

const LabeledDatepicker: React.FC<ButtonProps> = ({ label, selected, onChange, wrapperClassName, placeholderText, readOnly = false }) => (
    <FieldWrapper className={styles.datepickerWrapper}>
        <Label>{label}</Label>
        <DatePicker
            selected={selected}
            readOnly={readOnly}
            placeholderText={placeholderText}
            onChange={(date: any) => onChange(date)}
            className={classNames(styles.datePicker, readOnly ? styles.readonly : "")}
            showPopperArrow={false}
            dateFormat="yyyy-MM-dd"
            wrapperClassName={wrapperClassName}
        />
    </FieldWrapper>
);

export default LabeledDatepicker;
