import React, { ReactNode } from "react";

import styles from "./Label.module.scss";
import classNames from "classnames";

export interface ButtonProps {
    children?: ReactNode;
    className?: string;
}

const Label: React.FC<ButtonProps> = ({ children, className, ...props }) => (
    <label className={classNames(styles.label, className)} {...props}>
        {children}
    </label>
);

export default Label;
